import React, { FC, Fragment, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import { BsBankContacts, ModalBankContacts } from '#/src/components/ui/dialog-bank-contacts';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validationTempPasswordAlfaOnline } from '#/src/lib/client-validation/authorization';
import { TEMP_PASS_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { ButtonNames, FormStatus } from '#/src/models';
import { useRequestTempPasswordAuthorizationMutation } from '#/src/store/api/temp-password-api';
import { selectClientId, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    isTempPasswordButtonDisabled,
    selectIsTempPasswordError,
    selectTempPassword,
    selectTempPasswordAttemptsLeft,
    selectTempPasswordErrorPage,
    selectTempPasswordFormStatus,
} from '#/src/store/redux/temp-password';
import {
    tempPasswordErrorSet,
    tempPasswordSet,
    tempPasswordUpdated,
} from '#/src/store/redux/temp-password/slice';

import BackButton from '../../ui/back-button';
import StatusMessage from '../../ui/status-message';

import { ErrorScreenTempPass } from './components/error-screen';

import './temp-password-browser.css';

export const cn = createCn('temp-password-browser');

const RenderHeader = () => (
    <Fragment>
        <div className={cn('back-button')}>
            <BackButton />
        </div>
        <Typography.Title className={cn('title')} view='small' font='styrene' tag='div'>
            Введите временный пароль
        </Typography.Title>
    </Fragment>
);

const TempPasswordBrowser: FC = () => {
    const dispatch = useAppDispatch();
    const [requestTempPasswordAuthorization] = useRequestTempPasswordAuthorizationMutation();

    const error = useAppSelector(selectIsTempPasswordError);
    const password = useAppSelector(selectTempPassword);
    const attemptsLeft = useAppSelector(selectTempPasswordAttemptsLeft);
    const formStatus = useAppSelector(selectTempPasswordFormStatus);
    const buttonDisabled = useAppSelector(isTempPasswordButtonDisabled);
    const isMobile = useAppSelector(selectIsMobile);
    const validServerError = useAppSelector(selectTempPasswordErrorPage);
    const clientId = useAppSelector(selectClientId);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const [open, setOpen] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', clientId);

        return () => {
            dispatch(
                tempPasswordUpdated({
                    error: '',
                    tempPassword: '',
                    attemptsLeft: 2,
                    serverErrorId: '',
                }),
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        requestTempPasswordAuthorization();
    };

    const handleChange = (inputValue: string) => {
        if (!firstKeyPush) {
            trackUserEvent('Auth Page', 'Field Change', 'Enter TempPassword', clientId);
            setFirstKeyPush(true);
        }

        dispatch(tempPasswordSet(inputValue));
        const validation = validationTempPasswordAlfaOnline(inputValue);

        if (validation === null) {
            dispatch(tempPasswordErrorSet(''));
        } else {
            dispatch(tempPasswordErrorSet(validation));
        }
    };
    const handleBlur = () => {
        const validation = validationTempPasswordAlfaOnline(password, true);

        if (validation !== null && !error) {
            dispatch(tempPasswordErrorSet(validation));
        }
    };

    if (!attemptsLeft) {
        if (validServerError) {
            return <ErrorScreenTempPass />;
        }

        return <StatusMessage />;
    }

    return (
        <div className={cn('container')}>
            <RenderHeader />
            <Form className={cn()} noValidate={true} onSubmit={handleClick} autocomplete={false}>
                <Typography.Text view='primary-medium' tag='p'>
                    Чтобы получить пароль, позвоните в&nbsp;поддержку, обратитесь в&nbsp;офис банка
                    или&nbsp;спросите у&nbsp;сотрудника на&nbsp;встрече
                </Typography.Text>
                <PasswordInput
                    label='Пароль'
                    className={cn('input')}
                    size={isMobile ? 'l' : 'xl'}
                    block={true}
                    maxLength={TEMP_PASS_MAX_LENGTH}
                    onChange={(e: any) => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    value={password}
                    error={error}
                    autoFocus={true}
                    disabled={formStatus === FormStatus.SubmitProcess}
                    passwordVisible={passwordVisible}
                    onPasswordVisibleChange={(visible) => {
                        setPasswordVisible(visible);
                    }}
                    autoComplete='one-time-code'
                />
                <Gap size='xl' direction='vertical' />
                <Button
                    block={true}
                    view='primary'
                    disabled={buttonDisabled}
                    onClick={handleClick}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    {ButtonNames.continue}
                </Button>
                <Gap size='m' direction='vertical' />
                <Button block={true} view='secondary' onClick={() => setOpen(true)}>
                    Получить временный пароль
                </Button>
                {isMobile ? (
                    <BsBankContacts
                        title='Получить временный пароль'
                        open={open}
                        onClose={() => setOpen(false)}
                    />
                ) : (
                    <ModalBankContacts
                        open={open}
                        title='Получить временный пароль'
                        onClose={() => setOpen(false)}
                    />
                )}
            </Form>
        </div>
    );
};

export default TempPasswordBrowser;
