import React, { useEffect, useRef } from 'react';

import { PatternLockV1 } from '@alfalab/core-components/pattern-lock-v1';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { selectPasscodeError } from '#/src/store/redux/passcode';
import { passcodeSet, passcodeUpdated } from '#/src/store/redux/passcode/slice';

const PatternLockPasscode = () => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(selectPasscodeError);
    const clientId = useAppSelector(selectClientId);

    const patternLockInstance = useRef<any>();
    const timerId = useRef<NodeJS.Timeout>();

    const handleComplete = (code: number[]) => {
        trackUserEvent('Auth Page', 'Field Change', 'Enter Passcode', clientId, 'Graphic Pattern');
        dispatch(passcodeSet(code.join('')));
    };

    useEffect(() => {
        if (error !== '') {
            timerId.current = setTimeout(() => {
                dispatch(passcodeUpdated({ error: '', passcode: '' }));
            }, 2000);
        }
        patternLockInstance?.current?.setInitialState();

        return () => {
            if (timerId && timerId.current) {
                clearTimeout(timerId.current);
                timerId.current = undefined;
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <PatternLockV1
            extraBounds={[40, 40, 40, 40]}
            ref={patternLockInstance}
            onComplete={handleComplete}
            error={error}
        />
    );
};

export default PatternLockPasscode;
