import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import AlfaIdEnMColorIcon from '@alfalab/icons/logo/dist/AlfaIdEnMColorIcon';
import { DoorOpenMIcon } from '@alfalab/icons-glyph/DoorOpenMIcon';
import AlfaPayMIcon from '@alfalab/icons-logotype/AlfaPayMIcon';

import { BankLogo } from '#/src/components/ui/bank-logo';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { clientInfoLog } from '#/src/lib/client-logger';
import { ButtonNames, LocalStorageName, Routes } from '#/src/models';
import { pincodeVisitedSet } from '#/src/store/pincode/slice';
import { selectBecomeClientUrl, selectIsMobile } from '#/src/store/redux/app/selectors';
import { selectCustomDesignClient, selectCustomDesignTitle } from '#/src/store/redux/custom-design/selectors';
import { BANK_URL } from '#/src/utils/constants';

import './header.css';

const cn = createCn('browser-header');

const PAGES_WITHOUT_HEADER: string[] = [Routes.AKEY];
const PAGES_WITH_BECOME_CLIENT: string[] = [Routes.LOGIN, Routes.PHONE_AUTH];

interface Props {
    showHint: boolean;
}

const headerLogo = (customDesignTitle: string, isMobile: boolean, isAlfaId: boolean) => {
    if (customDesignTitle) {
        if (isAlfaId) {
            return (
                <AlfaIdEnMColorIcon
                    {...(isMobile ? { width: '90', height: '36' } : { width: '75', height: '40' })}
                />
            );
        }

        return (
            <Button dataTestId='header-logo' className={cn( 'logo' )} view='text' href={BANK_URL}>
                <AlfaPayMIcon
                    {...(isMobile ? { width: '100', height: '32' } : { width: '122', height: '48' })}
                />
            </Button>
        );
    }

    return (
        <Button dataTestId='header-logo' className={cn( 'logo' )} view='text' href={BANK_URL}>
            <BankLogo {...(isMobile ? { width: '32', height: '32' } : { width: '48', height: '48' })} />
        </Button>
    );
};

const Header: React.FC<Props> = ({ showHint }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignClient = useAppSelector(selectCustomDesignClient);
    const clickUrl = useAppSelector(selectBecomeClientUrl);
    const isMobile = useAppSelector(selectIsMobile);
    const isAlfaId = customDesignClient === 'alfaId';

    const handleUserChange = async () => {
        window.localStorage.removeItem(LocalStorageName.browserSecret);
        window.localStorage.removeItem(LocalStorageName.browserSecretDate);
        window.localStorage.removeItem(LocalStorageName.xUserInfo);
        clientInfoLog('ForgottenPasscode - remove browserSecret and browserSecretDate');

        dispatch(pincodeVisitedSet(true));
        navigate({
            pathname: Routes.LOGIN,
            search,
        });
    };

    const showBecomeClientButton = PAGES_WITH_BECOME_CLIENT.some((page) =>
        new RegExp(`${page}$`).test(pathname),
    );

    const showLogo = !isMobile || showBecomeClientButton;

    const showChangeUserButton = pathname.endsWith(Routes.PINCODE);

    const isPageWithoutHeader = PAGES_WITHOUT_HEADER.some((page) =>
        new RegExp(`${page}$`).test(pathname),
    );

    const emptyHeader = !showLogo && !showBecomeClientButton && !showChangeUserButton;

    if (emptyHeader || isPageWithoutHeader) return null;

    return (
        <header className={cn()}>
            {showLogo ? (
                headerLogo( customDesignTitle, isMobile,  isAlfaId )
            ) : null}

            {showHint ? (
                <Button
                    dataTestId='header-button'
                    className={cn( 'button' )}
                    view='secondary'
                    href={`${BANK_URL}/faq/alfaonline`}
                    size='xxs'
                >
                    ❤️ Как тут всё работает
                </Button>
            ) : null}

            {showChangeUserButton ? (
                <Button view='text' className={cn('change-user-button')} onClick={handleUserChange} dataTestId='header-button'>
                    <GenericWrapper alignItems='center'>
                        <DoorOpenMIcon/>
                        {!isMobile && (
                            <React.Fragment>
                                <Gap size='s' direction='horizontal'/> Сменить пользователя
                            </React.Fragment>
                        )}
                    </GenericWrapper>
                </Button>
            ) : null}

            {showBecomeClientButton ? (
                <Button
                    dataTestId='header-button'
                    className={cn( 'button' )}
                    view='text'
                    href={clickUrl}
                    size='s'
                >
                    {ButtonNames.becomeClient}
                </Button>
            ) : null}
        </header>
    );
};

export default Header;
