import React, { FC, useEffect, useRef } from 'react';
import { createCn } from 'bem-react-classname';

import { PassCodeV1 } from '@alfalab/core-components/pass-code-v1';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { selectPasscode, selectPasscodeError } from '#/src/store/redux/passcode';
import { passcodeSet, passcodeUpdated } from '#/src/store/redux/passcode/slice';

import './numeric.css';

const cn = createCn('numeric');

const NumericPassCode: FC = () => {
    const dispatch = useAppDispatch();

    const passcode = useAppSelector(selectPasscode);
    const error = useAppSelector(selectPasscodeError);
    const clientId = useAppSelector(selectClientId);

    const timerId = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (error !== '') {
            timerId.current = setTimeout(() => {
                dispatch(passcodeUpdated({ error: '', passcode: '' }));
            }, 2000);
        }

        return () => {
            if (timerId && timerId.current) {
                clearTimeout(timerId.current);
                timerId.current = undefined;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (error !== '') {
            dispatch(passcodeUpdated({ error: '', passcode: '' }));
            if (timerId && timerId.current) {
                clearTimeout(timerId.current);
                timerId.current = undefined;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passcode]);

    const handleChange = (value: string) => {
        dispatch(passcodeSet(value));
        trackUserEvent('Auth Page', 'Field Change', 'Enter Passcode', clientId, 'Numeric');
    };

    return (
        <PassCodeV1
            className={cn('container')}
            value={passcode}
            error={error}
            onChange={handleChange}
        />
    );
};

export default NumericPassCode;
