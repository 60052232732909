import React, { FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { InternationalPhoneInput } from '@alfalab/core-components/international-phone-input';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';

import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { fetchers } from '#/src/lib/client-api';
import {
    FORM_FIELD_SIZE,
    FORM_PHONE_INPUT_SIZE,
    PHONE_INPUT_MIN_LENGTH,
    PHONE_INPUT_MIN_LENGTH_RU,
} from '#/src/lib/form-controls-const';
import { formatPhoneNumber } from '#/src/lib/formatters';
import { isBrokenAmVersion } from '#/src/lib/is-broken-am-version';
import { enterDemoOnIOS } from '#/src/lib/passport-mobile-bridge';
import { phoneNumberValidate } from '#/src/lib/validators';
import { ButtonNames, DEMO_PHONE_NUMBER, FormStatus, RegistrationType } from '#/src/models';
import { useRequestPhoneRegistrationMutation } from '#/src/store/api/registration-api';
import {
    getQueryRedirectParams,
    selectIosAppIdRedirect,
    selectIosAppVersionRedirect,
} from '#/src/store/redux/app/selectors';
import {
    getRegistrationFormStatus,
    getRegistrationPhone,
    getRegistrationServerErrors,
    phoneAuthErrorMessage,
} from '#/src/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeUpdated,
} from '#/src/store/redux/registration/slice';

import errorDictionary from '../../../error-dictionary';
import InputCase from '../../ui/input-case';

const cn = createCn('form-basic');

const PhoneAuthNew: FC = () => {
    const dispatch = useAppDispatch();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();
    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorMessage = useAppSelector(phoneAuthErrorMessage);
    const iosAppVersionRedirect = useAppSelector(selectIosAppVersionRedirect);
    const iosAppIdRedirect = useAppSelector(selectIosAppIdRedirect);

    const [firstKeyPush, setFirstKeyPush] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const submitFormWithPhoneFromQuery = () => {
        dispatch(
            registrationFormUpdated({
                phone: formatPhoneNumber(queryRedirectParams.phone),
            }),
        );
        requestPhoneRegistration();
    };

    useIsomorphicLayoutEffect(() => {
        if (isBrokenAmVersion(queryRedirectParams, { iosAppIdRedirect, iosAppVersionRedirect })) {
            (async () => {
                const response = await fetchers.retrieveAmPhoneRedirectUrl(
                    queryRedirectParams.device_uuid,
                );

                if (response === true) {
                    // 1 редирект закрывает вебвью
                    // 2 редирект ставит флаг, что регистрация успешна
                    window.location.assign(
                        'registerRedirect?access_token=fake_access_token&refresh_token=fake_refresh_token',
                    );
                    setTimeout(() => {
                        window.location.assign(
                            'registerRedirect?access_token=fake_access_token&refresh_token=fake_refresh_token',
                        );
                    }, 700);
                }
            })();
        }

        dispatch(registrationTypeUpdated(RegistrationType.Phone));

        const isPhoneValid = phoneNumberValidate(queryRedirectParams.phone, { withLength: true });

        if (!isPhoneValid) {
            setIsFormVisible(true);
        }

        if (isPhoneValid) {
            setIsFormVisible(false);
            submitFormWithPhoneFromQuery();
        }

        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    }, [formStatus, requestPhoneRegistration]);

    useEffect(() => {
        if (errorMessage) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Phone Send',
                queryRedirectParams.client_id,
                `Error: ${errorMessage}`,
            );
        }
    }, [errorMessage, queryRedirectParams.client_id]);

    useEffect(() => {
        if (formStatus === FormStatus.SubmitError) {
            setIsFormVisible(true);
        }
    }, [formStatus]);

    const handleSubmit = (event?: React.FormEvent<any>) => {
        event?.preventDefault();

        trackUserEvent(
            'Auth Page',
            'Click',
            'Phone Send',
            queryRedirectParams.client_id,
            'Sending',
        );
        if (phone === DEMO_PHONE_NUMBER) {
            enterDemoOnIOS();
        } else {
            dispatch(
                phoneRegistrationSubmit({
                    type: RegistrationType.Phone,
                    phone,
                }),
            );
        }
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    };

    const handlePhoneChange = (phoneValue: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Phone Number',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        dispatch(registrationFormUpdated({ phone: phoneValue }));
        if (errorMessage) {
            dispatch(registrationServerErrorsCleared());
        }
    };

    const isSubmitButtonDisabled = () => {
        if (phone) {
            const phoneNumber = phone.replace(/\D/g, '');
            const PHONE_INPUT_MIN_LENGTH_RU_CHECKED = /^7/.test(phoneNumber)
                ? PHONE_INPUT_MIN_LENGTH_RU
                : PHONE_INPUT_MIN_LENGTH;

            return phoneNumber.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED || !!errorMessage;
        }

        return true;
    };

    const isGetCardButtonVisible = () =>
        Array.isArray(serverErrors) &&
        serverErrors.length &&
        serverErrors[0].message === errorDictionary.PHONE_NOT_FOUND;

    if (!isFormVisible) return null;

    return (
        <Form className={cn({ flex: 'space-between' })} onSubmit={handleSubmit}>
            <FormField size={FORM_FIELD_SIZE}>
                <InputCase>
                    <InternationalPhoneInput
                        dataTestId='phoneInput'
                        block={true}
                        clear={true}
                        size={FORM_PHONE_INPUT_SIZE}
                        clearableCountryCode={true}
                        autoFocus={true}
                        value={phone}
                        error={errorMessage}
                        colors='default'
                        label='Номер телефона'
                        onChange={handlePhoneChange}
                    />
                </InputCase>
            </FormField>
            {isGetCardButtonVisible() ? (
                <Button
                    block={true}
                    type='button'
                    view='primary'
                    // TODO: replace url
                    href='https://anketa.alfabank.ru/navigator/click-unauth'
                >
                    {ButtonNames.getCard}
                </Button>
            ) : (
                <Button
                    block={true}
                    type='submit'
                    view='primary'
                    disabled={isSubmitButtonDisabled()}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    {ButtonNames.continue}
                </Button>
            )}
        </Form>
    );
};

export default PhoneAuthNew;
