import React, { FC } from 'react';

import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { PureCell } from '@alfalab/core-components/pure-cell';
import { AsterisksMIcon } from '@alfalab/icons-glyph/AsterisksMIcon';
import { KeyMIcon } from '@alfalab/icons-glyph/KeyMIcon';
import { UserScanMIcon } from '@alfalab/icons-glyph/UserScanMIcon';

import { useAppSelector } from '#/src/hooks';
import { Routes } from '#/src/models';
import { selectIsWebView } from '#/src/store/redux/app/selectors';

export type AuthType = 'temp-password' | 'passcode' | 'new-passcode' | 'biometry';

type Props = {
    authType: AuthType;
    onClick: () => void;
};

const colorIcon = 'var(--color-light-neutral-translucent-1300)';

export const AUTH_TYPE_PROPS = {
    'temp-password': {
        title: 'Временный пароль',
        icon: <KeyMIcon color={colorIcon} />,
        typeEvent: 'App Auth TempPassword',
        route: Routes.TEMP_PASSWORD,
    },
    passcode: {
        title: 'Секретный код',
        icon: <AsterisksMIcon color={colorIcon} />,
        typeEvent: 'App Auth Passcode',
        route: Routes.PASSCODE,
    },
    'new-passcode': {
        title: 'Секретный код',
        icon: <AsterisksMIcon color={colorIcon} />,
        typeEvent: 'App Auth Passcode',
        route: Routes.NEW_PASSCODE,
    },
    biometry: {
        title: 'Биометрия лица',
        icon: <UserScanMIcon color={colorIcon} />,
        typeEvent: 'App Auth Biometry',
        route: Routes.BIOMETRY,
    },
};

export const AuthCellBase: FC<Props> = ({ authType, onClick }) => {
    const { title, icon } = AUTH_TYPE_PROPS[authType];
    const isWebview = useAppSelector(selectIsWebView);

    return (
        <PureCell
            direction='horizontal'
            verticalPadding='default'
            onClick={onClick}
            dataTestId='purecell'
        >
            <PureCell.Graphics>
                <SuperEllipse
                    size={48}
                    border={!isWebview}
                    backgroundColor='var(--color-light-neutral-translucent-100)'
                >
                    {icon}
                </SuperEllipse>
            </PureCell.Graphics>
            <PureCell.Content>
                <PureCell.Main>
                    <PureCell.Text titleColor='primary' view='component-primary'>
                        {title}
                    </PureCell.Text>
                </PureCell.Main>
            </PureCell.Content>
        </PureCell>
    );
};
