import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import BackButton from '#/src/components/ui/back-button';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { clientInfoLog } from '#/src/lib/client-logger';
import { validatePincode } from '#/src/lib/client-validation/authorization';
import { expiresDate } from '#/src/lib/expires-date';
import { LOGIN_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { setCookie } from '#/src/lib/update-cookie';
import { getUserLocalInfo } from '#/src/lib/user-info';
import { ButtonNames, CookiesName, Routes } from '#/src/models';
import { usePincodeAuthorizationMutation } from '#/src/store/pincode/api';
import {
    selectIsPincodeButtonDisabled,
    selectPincode,
    selectPincodeAttemptsLeft,
    selectPincodeError,
} from '#/src/store/pincode/selectors';
import { pincodeErrorSet, pincodeSet, pincodeVisitedSet } from '#/src/store/pincode/slice';
import {
    selectCustomDesignBackUrl,
    selectCustomDesignClient,
    selectCustomDesignSubtitle,
    selectCustomDesignTitle,
} from '#/src/store/redux/custom-design/selectors';
import { SUB_ALFABANK_DOMAIN } from '#/src/utils';

import { ErrorScreen } from './components/error-screen';
import { Modal } from './components/modal';

import './pincode-browser.css';

export const cn = createCn('pincode-browser');

const getHeader = (
    customDesignTitle: string,
    customDesignSubtitle: string,
    customDesignClient: string,
    xUserInfoName: string,
) => {
    if (customDesignTitle) {
        const subtitle = (() => {
            if (customDesignSubtitle) {
                return customDesignSubtitle
            }

            if (customDesignClient === 'alfaId') {
                return <React.Fragment>Войдите в&nbsp;свой аккаунт Альфа-Банка</React.Fragment>
            }

            return <React.Fragment>Для оплаты войдите в&nbsp;Альфа-Онлайн</React.Fragment>
            })()

        return (
            <React.Fragment>
                <Typography.TitleResponsive
                    rowLimit={2}
                    className={cn('title')}
                    dataTestId='merchant-title'
                    view='medium'
                    font='styrene'
                    tag='h1'
                    defaultMargins={true}
                >
                    {customDesignTitle}
                </Typography.TitleResponsive>
                <Typography.Text
                    className={cn('subtitle')}
                    dataTestId='merchant-subtitle'
                    view='primary-large'
                    rowLimit={2}
                    tag='div'
                >
                    {subtitle}
                </Typography.Text>
            </React.Fragment>
        );
    }

    if (xUserInfoName) {
        return (
            <Typography.TitleResponsive
                className={cn('title')}
                view='medium'
                font='styrene'
                tag='h1'
                defaultMargins={true}
                dataTestId='pincode-title'
            >
                Привет, {xUserInfoName}!
                <br />
                Войдите в&nbsp;Альфа-Онлайн
            </Typography.TitleResponsive>
        );
    }

    return (
        <Typography.TitleResponsive
            dataTestId='pincode-title'
            view='medium'
            font='styrene'
            tag='h1'
            defaultMargins={true}
        >
            Привет!
            <br />
            Войдите в&nbsp;Альфа-Онлайн
        </Typography.TitleResponsive>
    );
};

const PincodeBrowser: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const [requestPincodeAuthorization] = usePincodeAuthorizationMutation();

    const attempts = useAppSelector(selectPincodeAttemptsLeft);
    const customDesignBackUrl = useAppSelector(selectCustomDesignBackUrl);
    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignSubtitle = useAppSelector(selectCustomDesignSubtitle);
    const customDesignClient = useAppSelector(selectCustomDesignClient);
    const code = useAppSelector(selectPincode);
    const error = useAppSelector(selectPincodeError);
    const xUserInfo = getUserLocalInfo();
    const buttonDisabled = useAppSelector(selectIsPincodeButtonDisabled);

    const [open, setOpen] = useState(false);

    const onChange = (codeValue: string) => {
        const validation = validatePincode(codeValue);

        if (validation && validation !== error) {
            dispatch(pincodeErrorSet(validation));
        } else if (!validation && error) {
            dispatch(pincodeErrorSet(''));
        }

        dispatch(pincodeSet(codeValue));
    }

    const handleSubmit = () => {
        const validation = validatePincode(code);

        if (validation === null) {
            requestPincodeAuthorization();
        } else {
            dispatch(pincodeErrorSet(validation));
            dispatch(pincodeSet(''));
        }
    };

    if (!attempts) {
        return (
            <ErrorScreen
                onChange={() => {
                    navigate({
                        pathname: Routes.LOGIN,
                        search,
                    });
                }}
            />
        );
    }

    return (
        <div className={cn('container')}>
            {customDesignBackUrl && (
                <div className={cn('back-button')}>
                    <BackButton backUrl={customDesignBackUrl} />
                </div>
            )}
            {getHeader(customDesignTitle, customDesignSubtitle, customDesignClient, xUserInfo.name)}

            <Form className={cn('form')} noValidate={true} onSubmit={handleSubmit}>
                <PasswordInput
                    className={cn('input')}
                    value={code}
                    autoFocus={true}
                    maxLength={LOGIN_INPUT_MAX_LENGTH}
                    label='Введите секретный код'
                    clear={true}
                    size='xl'
                    block={true}
                    error={error}
                    autoCapitalize='none'
                    onChange={(e: any) => onChange(e.target.value)}
                    onClear={() => onChange('')}
                />
                <Button
                    className={cn('button')}
                    type='submit'
                    view='primary'
                    block={true}
                    disabled={buttonDisabled}
                >
                    {ButtonNames.forward}
                </Button>
                <Gap size='xl' direction='vertical' />
                <Button
                    className={cn('button')}
                    view='text'
                    block={true}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Забыли код?
                </Button>
            </Form>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onClick={() => {
                    setCookie(CookiesName.forgottenPasscode, 'true', {
                        domain: SUB_ALFABANK_DOMAIN,
                        path: '/',
                        expires: expiresDate(7),
                    });
                    clientInfoLog('forgottenPasscode set');
                    navigate({
                        pathname: Routes.LOGIN,
                        search,
                    });
                    dispatch(pincodeVisitedSet(true));
                }}
            />
        </div>
    );
};

export default PincodeBrowser;
