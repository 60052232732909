import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import PlateNotification from '#/src/components/app/app-browser/header/plate-notification/plate-notification';

import LogoHeader from '../logo-header';

import './app-header.css';

const cn = createCn('app-header');

const AppHeader: FC = () => (
        <div className={cn()}>
            <PlateNotification/>
            <LogoHeader />
        </div>
    )

export default AppHeader;
