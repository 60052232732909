import qs from 'qs';
import request from 'superagent';
import uuid from 'uuid';

import initializeTraceId from '#/src/lib/initialize-trace-id';
import { HEADERS, HttpMethod } from '#/src/utils/constants';

import getCookie from './get-cookie';
import { RetryOptions } from '#/src/types/interfaces';

const customHeaders = initializeTraceId();

function filterNulls(prefix: string, value: string | number | null) {
    return value === null ? undefined : value;
}

const DEFAULT_FACTORY_FETCH_OPTIONS = {
    allowMultiRequest: false,
};

export default function factoryFetch(
    endpoint: string,
    method: HttpMethod = HttpMethod.GET,
    options = DEFAULT_FACTORY_FETCH_OPTIONS,
) {
    let lastRequest: any;

    const fetcher = function (data?: any, retryOptions?: RetryOptions): Promise<any> {
        if (lastRequest && !options.allowMultiRequest) {
            lastRequest.abort();
        }

        return new Promise((resolve: any, reject: any) => {
            let cstmRequest;

            if (method === HttpMethod.POST) {
                cstmRequest = request
                    .post(endpoint)
                    .send(  data)
                    .set(HEADERS.X_CSRF_TOKEN, getCookie('alfa-csrf'))
                    .set(HEADERS.X_REQUEST_ID, uuid.v4())
                    .set(HEADERS.TRACE_ID, customHeaders[HEADERS.TRACE_ID]);
            } else {
                cstmRequest = request
                    .get(endpoint)
                    .query(qs.stringify(data, { filter: filterNulls }))
                    .set(HEADERS.X_CSRF_TOKEN, getCookie('alfa-csrf'))
                    .set(HEADERS.X_REQUEST_ID, uuid.v4())
                    .set(HEADERS.TRACE_ID, customHeaders[HEADERS.TRACE_ID]);
            }

            cstmRequest.on('abort', () => {
                reject();
            });

            lastRequest = cstmRequest;

            cstmRequest.end((err, data) => {
                if (!err) {
                    resolve(data.body || data.text);
                } else if (err.response && err.response.body && err.response.body.errors) {
                    reject(err.response.body.errors);
                } else {
                    const error = [{ detail: err.message }];

                    reject(error);
                }
            });
        }).then(
            (result) => {
                lastRequest = null;

                return result;
            },
            (error) => {
                lastRequest = null;
                throw error;
            },
        ).catch((errors) => {
            if (retryOptions?.retryCount && errors[0]?.status >= 500 && errors[0]?.status <= 599) {
                return fetcher(data, {retryCount: retryOptions.retryCount - 1});
            }

            throw errors;
        });
    };

    fetcher.abortLastRequest = () => lastRequest && lastRequest.abort();

    return fetcher;
}