import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { PasswordInput } from '@alfalab/core-components/password-input';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { selectPasscode, selectPasscodeError } from '#/src/store/redux/passcode';
import { passcodeSet, passcodeUpdated } from '#/src/store/redux/passcode/slice';

import './alpha-numeric.css';

const cn = createCn('alpha-numeric');

const AlphaNumericPasscode: FC = () => {
    const dispatch = useAppDispatch();

    const passcode = useAppSelector(selectPasscode);
    const error = useAppSelector(selectPasscodeError);
    const clientId = useAppSelector(selectClientId);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const timerId = useRef<NodeJS.Timeout>();
    const inputFocus = useRef<HTMLInputElement>(null);

    const handleChange = (value: string) => {
        dispatch(passcodeSet(value));
        trackUserEvent('Auth Page', 'Field Change', 'Enter Passcode', clientId, 'Alpha Numeric');
    };

    useEffect(() => {
        if (error !== '') {
            timerId.current = setTimeout(() => {
                dispatch(passcodeUpdated({ error: '', passcode: '' }));
            }, 2000);
        }

        return () => {
            if (timerId && timerId.current) {
                clearTimeout(timerId.current);
                timerId.current = undefined;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <PasswordInput
            value={passcode}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            onClick={() =>
                setTimeout(
                    () =>
                        inputFocus.current?.scrollIntoView({ block: 'center', behavior: 'smooth' }),
                    200,
                )
            }
            className={cn('container')}
            passwordVisible={passwordVisible}
            onPasswordVisibleChange={(visible) => {
                inputFocus.current?.focus();
                setPasswordVisible(visible);
            }}
            ref={inputFocus}
            block={true}
            error={error}
        />
    );
};

export default AlphaNumericPasscode;
