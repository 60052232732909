import { FormStatus } from '#/src/models';
import { PincodeState } from '#/src/store/pincode/types';

export const PincodeInitialState: PincodeState = {
    attemptsLeft: 0,
    nonce: '',
    passcodeType: '',
    serverPublicKey: '',
    code: '',
    error: '',
    isVisited: false,
    message: null,
    mfaToken: '',
    redirectUrl: '',
    signatureBase64: '',
    formStatus: FormStatus.Initial,
    success: false,
    newBrowserSecret: '',
    newBrowserSecretEnc: '',
};
