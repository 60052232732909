import { createSelector } from 'reselect';

import getCookie from '#/src/lib/get-cookie';
import { setCookie } from '#/src/lib/update-cookie';
import { CookiesName } from '#/src/models';
import { ApplicationState } from '#/src/store/types';

export interface CustomDesignProps {
    title: string;
    subtitle?: string;
    authorizationUrl?: string;
    backUrl?: string;
    client?: string;
}

export const getCustomDesignState = (state: ApplicationState) => state.CustomDesign;

export const selectCustomDesignBackUrl = createSelector([getCustomDesignState], (customDesign) => customDesign.backUrl);
export const selectCustomDesignTitle = createSelector([getCustomDesignState], (customDesign) => customDesign.title);
export const selectCustomDesignSubtitle = createSelector(
    [getCustomDesignState],
    (customDesign) => customDesign.subtitle,
);
export const selectCustomDesignAuthorizationUrl = createSelector(
    [getCustomDesignState],
    (customDesign) => customDesign.authorizationUrl,
);
export const selectCustomDesignClient = createSelector(
    [getCustomDesignState],
    (customDesign) => customDesign.client,
);


export const getCustomDesignCookies = (isMobile: boolean): Partial<CustomDesignProps> => {
    const merchantTitle = getCookie(CookiesName.customDesignTitle);
    const merchantAuthorizationUrl = getCookie(CookiesName.customDesignAuthorizationUrl);
    const merchantBackUrl = getCookie(CookiesName.customDesignBackUrl);
    const merchantClient = getCookie(CookiesName.customDesignClient);
    let merchantSubtitle = getCookie(CookiesName.customDesignSubtitle);

    if (merchantTitle && ( merchantBackUrl || merchantClient === 'alfaId' )) {
        setCookie(CookiesName.customDesignTitle, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.customDesignSubtitle, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.customDesignAuthorizationUrl, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.customDesignBackUrl, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.customDesignClient, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });

        if (isMobile) {
            if (merchantClient === 'alfaId') {
                merchantSubtitle = 'Войдите в свой аккаунт Альфа-Банка';
            } else {
                merchantSubtitle = '';
            }
        }

        return {
            title: merchantTitle,
            subtitle: merchantSubtitle,
            authorizationUrl: merchantAuthorizationUrl,
            backUrl: merchantBackUrl,
            client: merchantClient,
        };
    }

    return {};
};

// Старая реализация (удалить в случаен переноса кук APay)
export const getAlfaPayCookies = (isMobile: boolean): Partial<CustomDesignProps> => {
    const merchantTitle = getCookie(CookiesName.alfaPayMerchantTitle);
    const merchantSubtitle = getCookie(CookiesName.alfaPayMerchantSubtitle);
    const merchantAuthorizationUrl = getCookie(CookiesName.alfapayAuthorizationUrl);
    const merchantBackUrl = getCookie(CookiesName.alfaPayMerchantBackUrl);

    if (merchantTitle && merchantBackUrl) {
        setCookie(CookiesName.alfaPayMerchantTitle, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.alfaPayMerchantSubtitle, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.alfapayAuthorizationUrl, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.alfaPayMerchantBackUrl, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });

        return {
            title: merchantTitle,
            subtitle: isMobile ? '' : merchantSubtitle,
            authorizationUrl: merchantAuthorizationUrl,
            backUrl: merchantBackUrl,
        };
    }

    return {};
};
