import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AKeyAuth from '#/src/components/akey-auth';
import App from '#/src/components/app';
import Biometry from '#/src/components/biometry';
import CardAccount from '#/src/components/card-account';
import CardAccountNotClient from '#/src/components/card-account-not-client/card-account-not-client';
import DemoUserSignin from '#/src/components/demouser-signin/demouser-signin';
import Error from '#/src/components/error';
import Explanation from '#/src/components/explanation/';
import FastRegistration from '#/src/components/fast-registration';
import FinishSignIn from '#/src/components/finish-signin';
import ForgotLogin from '#/src/components/forgot-login';
import ForgotPassword from '#/src/components/forgot-password';
import FullNameNotClient from '#/src/components/full-name-not-client';
import FullNameRegistration from '#/src/components/full-name-registration';
import Investments from '#/src/components/investments';
import JWTAuth from '#/src/components/jwt-auth';
import Login from '#/src/components/login';
import Offer from '#/src/components/offer/offer';
import NewPasscode from '#/src/components/passcode';
import Passcode from '#/src/components/passcode/passcode-mobile';
import Password from '#/src/components/password';
import PhoneLogin from '#/src/components/phone';
import PhoneAuth from '#/src/components/phone-auth';
import Pincode from '#/src/components/pincode';
import Recovery from '#/src/components/recovery';
import Sms from '#/src/components/sms';
import TempPassword from '#/src/components/temp-password-auth';
import TempPasswordBySms from '#/src/components/temp-password-by-sms';
import WebAuthn from '#/src/components/webauthn';
import WebAuthnReg from '#/src/components/webauthn-reg';

import { Routes as ROUTES } from '../models';

// <Route path='*' element={<Error />} />
const RouterRoutes = () => (
    <Routes>
        <Route path='/' element={<App />}>
            <Route path={ROUTES.AD_USERNAME_PASSWORD.substring(1)} element={<Investments />} />
            <Route path={ROUTES.CARD_ACCOUNT.substring(1)} element={<CardAccount />} />
            <Route path={ROUTES.CORP_USERNAME.substring(1)} element={<Login />} />
            <Route path={ROUTES.FAST_REGISTRATION.substring(1)} element={<FastRegistration />} />
            <Route path={ROUTES.FINISH_SIGNIN.substring(1)} element={<FinishSignIn />} />
            <Route path={ROUTES.DIRECT_DEMO_USER.substring(1)} element={<DemoUserSignin />} />
            <Route path={ROUTES.LOGIN.substring(1)} element={<Login />} />
            <Route path={ROUTES.PHONE_LOGIN.substring(1)} element={<PhoneLogin />} />
            <Route path={ROUTES.PHONE_AUTH.substring(1)} element={<PhoneAuth />} />
            <Route
                path={ROUTES.FULL_NAME_NOT_CLIENT.substring(1)}
                element={<FullNameNotClient />}
            />
            <Route path={ROUTES.OFFER.substring(1)} element={<Offer />} />
            <Route path={ROUTES.UPDATE.substring(1)} element={<Recovery />} />
            <Route path={ROUTES.SMS.substring(1)} element={<Sms />} />
            <Route path={ROUTES.CORP_SMS.substring(1)} element={<Sms />} />
            <Route path={ROUTES.EXPLANATION.substring(1)} element={<Explanation />} />
            <Route
                path={ROUTES.FULLNAME_REGISTRATION.substring(1)}
                element={<FullNameRegistration />}
            />
            <Route
                path={ROUTES.CARD_ACCOUNT_NOT_CLIENT.substring(1)}
                element={<CardAccountNotClient />}
            />
            <Route path={ROUTES.FORGOT_LOGIN.substring(1)} element={<ForgotLogin />} />
            <Route path={ROUTES.FORGOT_PASSWORD.substring(1)} element={<ForgotPassword />} />
            <Route
                path={ROUTES.TEMP_PASSWORD_BY_SMS.substring(1)}
                element={<TempPasswordBySms />}
            />
            <Route path={ROUTES.EMPLOYEES.substring(1)} element={<Login />} />
            <Route path={ROUTES.ERROR.substring(1)} element={<Error />} />
            <Route path={ROUTES.AZON.substring(1)} element={<Login />} />
            <Route path={ROUTES.RESTORE.substring(1)} element={<CardAccount />} />
            <Route path={ROUTES.JWT_AUTH.substring(1)} element={<JWTAuth />} />
            <Route path={ROUTES.PASSWORD.substring(1)} element={<Password />} />
            <Route path={ROUTES.AKEY.substring(1)} element={<AKeyAuth />} />
            <Route path={ROUTES.PASSCODE.substring(1)} element={<Passcode />} />
            <Route path={ROUTES.PINCODE.substring(1)} element={<Pincode />} />
            <Route path={ROUTES.WEBAUTHN.substring(1)} element={<WebAuthn />} />
            <Route path={ROUTES.WEBAUTHN_REG.substring(1)} element={<WebAuthnReg />} />
            <Route path={ROUTES.TEMP_PASSWORD.substring(1)} element={<TempPassword />} />
            <Route path={ROUTES.NEW_PASSCODE.substring(1)} element={<NewPasscode />} />
            <Route path={ROUTES.BIOMETRY.substring(1)} element={<Biometry />} />
        </Route>
    </Routes>
);

export default RouterRoutes;
