import React, { useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import { useAppDispatch } from '#/src/hooks';
import { useAppSelector } from '#/src/hooks/use-app-selector';
import { trackUserEvent } from '#/src/lib/analitycs';
import { ButtonNames, FormStatus, PasscodeType } from '#/src/models';
import { useRequestPasscodeAuthorizationMutation } from '#/src/store/api/passcode-api';
import { selectClientId } from '#/src/store/redux/app/selectors';
import {
    selectPasscode,
    selectPasscodeAttemptsLeft,
    selectPasscodeErrorPage,
    selectPasscodeFormStatus,
    selectPasscodeType,
} from '#/src/store/redux/passcode';
import { passcodeUpdated } from '#/src/store/redux/passcode/slice';

import { ErrorScreenPasscodeAM } from './components/error-screen';
import { ServerErrorScreenPasscodeAM } from './components/server-error-screen';
import { SwitchPasscodeType } from './components/switch-passcode-type';

import './passcode.css';

export const cn = createCn('passcode');

const OldPasscodeMobile = () => {
    const [requestPasscodeAuthorization] = useRequestPasscodeAuthorizationMutation();

    const dispatch = useAppDispatch();

    const passcodeType = useAppSelector(selectPasscodeType);
    const passcode = useAppSelector(selectPasscode);
    const clientId = useAppSelector(selectClientId);
    const attemptsLeft = useAppSelector(selectPasscodeAttemptsLeft);
    const validServerError = useAppSelector(selectPasscodeErrorPage);
    const formStatus = useAppSelector(selectPasscodeFormStatus);

    useEffect(() => {
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', clientId);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            dispatch(
                passcodeUpdated({
                    error: '',
                    passcode: '',
                    attemptsLeft: 2,
                    serverErrorId: '',
                }),
            );
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        requestPasscodeAuthorization();
    };

    if (!attemptsLeft) {
        if (validServerError) {
            return <ErrorScreenPasscodeAM />;
        }

        return <ServerErrorScreenPasscodeAM />;
    }

    return (
        <Form
            noValidate={true}
            className={cn({
                flex: 'space-between',
            })}
        >
            <div>
                <Typography.Title className={cn('title')} view='xsmall' tag='div' color='primary'>
                    Введите код от приложения
                </Typography.Title>
                <Typography.Text
                    className={cn('text')}
                    view='primary-large'
                    tag='p'
                    color='primary'
                >
                    Вы&nbsp;придумали его и&nbsp;использовали последним для входа на&nbsp;этом
                    или&nbsp;другом устройстве
                </Typography.Text>
            </div>
            <SwitchPasscodeType passcodeType={passcodeType} />
            <Button
                className={
                    passcodeType === PasscodeType.Alphanumeric
                        ? cn('button-wrapper-alphanumeric')
                        : cn('button-wrapper')
                }
                block={true}
                disabled={
                    passcodeType === PasscodeType.Alphanumeric
                        ? passcode.length < 6
                        : passcode.length < 4
                }
                view='primary'
                onClick={(event: React.MouseEvent<HTMLElement>) => handleSubmit(event)}
                loading={formStatus === FormStatus.SubmitProcess}
            >
                {ButtonNames.continue}
            </Button>
        </Form>
    );
};

export default OldPasscodeMobile;