import { createSelector } from 'reselect';

import { validatePhoneNumber } from '#/src/lib/client-validation/registration';
import { PINCODE_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { clearFormatting } from '#/src/lib/formatters';
import { PasscodeType, ServerErrors } from '#/src/models';
import { selectClientId, selectIsMobile } from '#/src/store/redux/app/selectors';
import { ApplicationState } from '#/src/store/types';

import { getRegistrationPhone } from '../registration/selectors';

import { passcodeValidationErrors } from './constants';
import { PasscodeTypeValues } from './types';

export const selectPasscodeState = (state: ApplicationState) => state.PasscodeAuthorization;

const getEnroll = (state: ApplicationState, enroll: string) => enroll;

export const selectIsPasscodeAuth = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.isPasscodeAuth,
);

export const selectPasscode = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.passcode,
);

export const selectPasscodeAttemptsLeft = createSelector(
    [selectPasscodeState],
    (passocode) => passocode.attemptsLeft,
);

export const selectPasscodeServerErrorId = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.serverErrorId,
);

// TODO:CS2222-1157 Удалить после раскатки нового пасскода CS2222-1157
export const selectPasscodeType = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.passcodeType,
);

// 3 последних типа входа пользователя
export const selectPasscodeTypes = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.passcodeTypes,
);

export const selectPasscodeEnabled = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.passcodeEnabled,
);

export const selectPasscodeFormStatus = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.formStatus,
);

export const selectPasscodeError = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.error,
);

export const selectPasscodeServerPublicKey = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.serverPublicKey,
);

export const selectPasscodeNonce = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.nonce,
);

export const selectPasscodeShowToastError = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.showToastError,
);

export const selectPasscodeActiveType = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.activeTypePasscode,
);

export const selectIsAltAuthModalOpen = createSelector(
    [selectPasscodeState],
    (passcode) => passcode.isAltAuthModalOpen,
);

export const selectRequestDataInit = createSelector(
    [getRegistrationPhone, selectClientId, getEnroll],
    (phone, clientId, enroll) => {
        const phoneNumber = validatePhoneNumber(phone) === null ? clearFormatting(phone) : null;

        return {
            enroll,
            phone: phoneNumber,
            client_id: clientId,
        };
    },
);

export const selectClearPhoneNumber = createSelector([getRegistrationPhone], (phone) => {
    if (validatePhoneNumber(phone) === null) {
        return clearFormatting(phone) ?? undefined;
    }

    return undefined;
});

export const selectIsPasscodeButtonDisabled = createSelector(
    [selectPasscode, selectPasscodeError, selectPasscodeActiveType],
    (passcode, error, activePasscode) =>
        Boolean(
            passcode.length < passcodeValidationErrors[activePasscode].minLength ||
                passcode.length > PINCODE_MAX_LENGTH ||
                error,
        ),
);

export const selectPasscodeErrorPage = createSelector(
    [selectPasscodeAttemptsLeft, selectPasscodeServerErrorId],
    (attemptsLeft, serverErrorId) =>
        attemptsLeft === 0 && serverErrorId === ServerErrors.PASSCODE_VERIFICATION_IS_FAILED,
);

const content: { [key in PasscodeTypeValues]: string } = {
    [PasscodeType.Numeric]: 'Числовой код',
    [PasscodeType.GraphicPattern]: 'Графический ключ',
    [PasscodeType.Alphanumeric]: 'Число-буквенный код',
};

export type PasscodeOption = {
    key: PasscodeTypeValues;
    content: string;
};

export const selectOptionsPasscodeTypes = createSelector(selectPasscodeTypes, (passcodeTypes) => {
    if (passcodeTypes.length === 1) {
        return [];
    }
    const options: PasscodeOption[] = [];

    passcodeTypes.forEach((type: PasscodeTypeValues) => {
        options.push({ key: type, content: content[type] });
    });

    return options;
});

export const selectShowSwitchTypePasscodeButton = createSelector(
    [selectPasscodeTypes, selectIsMobile],
    (passcodeTypes, isMobile) => {
        if (isMobile) {
            return passcodeTypes.length > 1;
        }

        // Проверка для АО так как 2 типа кода в 1 инпуте
        return passcodeTypes.length > 1 && passcodeTypes.includes(PasscodeType.GraphicPattern);
    },
);
