import React from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import KremlinMIcon from '@alfalab/icons-glyph/KremlinMIcon';
import { PhoneBubbleMIcon } from '@alfalab/icons-glyph/PhoneBubbleMIcon';

import './help.css';

const cn = createCn('help');

interface Props {
    className?: string;
}

export const Help: React.FC<Props> = ({ className }) => (
    <div className={`${cn('container')} ${className}`}>
        <div className={cn()}>
            <div className={cn('icon')}>
                <KremlinMIcon color='#747474'/>
            </div>

            <div className={cn('description')}>
                <Button view='text' href='tel:+74957555858'>
                    +7 495 755-58-58
                </Button>
                <Typography.Text view='primary-small' color='secondary' className={cn('text')}>
                    В Москве и за границей
                </Typography.Text>
            </div>
        </div>
        <div className={cn()}>
            <div className={cn( 'icon' )}>
                <PhoneBubbleMIcon color='#747474'/>
            </div>

            <div className={cn('description')}>
                <Button view='text' href='tel:88002000000'>
                    8 800 200-00-00
                </Button>
                <Typography.Text view='primary-small' color='secondary' className={cn( 'text' )}>
                    Для звонков по России
                </Typography.Text>
            </div>
        </div>
    </div>
);
