import React, { FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { InternationalPhoneInput } from '@alfalab/core-components/international-phone-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import BackButton from '#/src/components/ui/back-button';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import {
    FORM_INPUT_SIZE,
    PHONE_INPUT_MIN_LENGTH,
    PHONE_INPUT_MIN_LENGTH_RU,
} from '#/src/lib/form-controls-const';
import { formatPhoneNumber } from '#/src/lib/formatters';
import { enterDemoOnIOS } from '#/src/lib/passport-mobile-bridge';
import { phoneNumberValidate } from '#/src/lib/validators';
import {
    ButtonNames,
    ClientIds,
    DEMO_PHONE_NUMBER,
    FormStatus,
    RegistrationType,
} from '#/src/models';
import { useRequestPhoneRegistrationMutation } from '#/src/store/api/registration-api';
import { getQueryRedirectParams } from '#/src/store/redux/app/selectors';
import {
    getRegistrationFormStatus,
    getRegistrationPhone,
    phoneAuthErrorMessage,
} from '#/src/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeUpdated,
} from '#/src/store/redux/registration/slice';

import './phone-auth.css';

const cn = createCn('phone-auth-browser');

const PhoneAuthBrowser: FC = () => {
    const dispatch = useAppDispatch();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();
    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorMessage = useAppSelector(phoneAuthErrorMessage);
    const showBackButton = queryRedirectParams.client_id !== ClientIds.lkGreenrobleWeb;

    const [firstKeyPush, setFirstKeyPush] = useState(false);

    useEffect(() => {
        const isPhoneValid = phoneNumberValidate(queryRedirectParams.phone, { withLength: true });

        dispatch(registrationTypeUpdated(RegistrationType.Phone));
        if (isPhoneValid) {
            dispatch(
                registrationFormUpdated({
                    phone: formatPhoneNumber(queryRedirectParams.phone),
                }),
            );
            requestPhoneRegistration();
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    }, [formStatus, requestPhoneRegistration]);

    useEffect(() => {
        if (errorMessage) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Phone Send',
                queryRedirectParams.client_id,
                `Error: ${errorMessage}`,
            );
        }
    }, [errorMessage, queryRedirectParams.client_id]);

    const handleSubmit = (event?: React.FormEvent<any>) => {
        event?.preventDefault();

        trackUserEvent(
            'Auth Page',
            'Click',
            'Phone Send',
            queryRedirectParams.client_id,
            'Sending',
        );

        if (phone === DEMO_PHONE_NUMBER) {
            enterDemoOnIOS();
        } else {
            dispatch(
                phoneRegistrationSubmit({
                    type: RegistrationType.Phone,
                    phone,
                }),
            );
        }
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    };

    const handlePhoneChange = (phoneValue: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Phone Number',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        dispatch(registrationFormUpdated({ phone: phoneValue }));

        if (errorMessage) {
            dispatch(registrationServerErrorsCleared());
        }
    };

    const isSubmitButtonDisabled = () => {
        if (phone) {
            const phoneNumber = phone.replace(/\D/g, '');
            const PHONE_INPUT_MIN_LENGTH_RU_CHECKED = /^7/.test(phoneNumber)
                ? PHONE_INPUT_MIN_LENGTH_RU
                : PHONE_INPUT_MIN_LENGTH;

            return phoneNumber.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED || !!errorMessage;
        }

        return true;
    };

    return (
        <div className={cn('container')}>
            {showBackButton ? (
                <div className={cn('btn-back')}>
                    <BackButton />
                </div>
            ) : null}

            <Form className={cn({ flex: 'space-between' })} onSubmit={handleSubmit}>
                <Typography.Title className={cn('title')} view='small' font='styrene' tag='h1'>
                    Введите номер телефона
                </Typography.Title>

                <div className={cn('input')}>
                    <InternationalPhoneInput
                        dataTestId='phoneInput'
                        block={true}
                        clear={true}
                        size={FORM_INPUT_SIZE}
                        clearableCountryCode={true}
                        autoFocus={true}
                        value={phone}
                        error={errorMessage}
                        colors='default'
                        label='Номер телефона'
                        onChange={handlePhoneChange}
                    />
                </div>

                <Button
                    block={true}
                    type='submit'
                    view='primary'
                    disabled={isSubmitButtonDisabled()}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    {ButtonNames.continue}
                </Button>
            </Form>
        </div>
    );
};

export default PhoneAuthBrowser;
