import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';

import { CatMessage } from '#/src/shared/cat-message';
import { Hint } from '#/src/components/sms/messenger-bank-sms/hint/hint';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { NEW_SMS_REQUEST_DELAY, SMS_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { FormStatus } from '#/src/models';
import {
    useRequestOIDSmsVerificationMutation,
    useRequestReferenceBySmsMutation,
} from '#/src/store/api/sms-verification-api';
import { formatMaskedPhoneNumber, getQueryRedirectParams } from '#/src/store/redux/app/selectors';
import { getRegistrationPhone } from '#/src/store/redux/registration/selectors';
import {
    selectSmsCode,
    selectSmsFormStatus,
    selectSmsServerError,
    selectSmsServerErrorMsg,
} from '#/src/store/redux/sms-verification/selectors';
import {
    smsServerErrorNotificationClosed,
    smsVerificationFormUpdated,
    smsVerificationServerErrorsReset,
    smsVerificationStatusRequested,
    smsVerificationSubmit,
} from '#/src/store/redux/sms-verification/slice';

import { CustomPinIndicator } from './custom-pin-indicator/pin-indicator';

import './messenger-bank-sms.css';

const cn = createCn('messenger-sms-bank');

const MessengerBankSms: FC = () => {
    const dispatch = useAppDispatch();
    const [requestOIDSmsVerification] = useRequestOIDSmsVerificationMutation();
    const [requestReferenceBySms] = useRequestReferenceBySmsMutation();

    const formStatus = useAppSelector(selectSmsFormStatus);
    const code = useAppSelector(selectSmsCode);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const serverError = useAppSelector(selectSmsServerError);
    const serverErrorMessage = useAppSelector(selectSmsServerErrorMsg);
    const formatedMaskedPhoneNumber = useAppSelector(formatMaskedPhoneNumber);
    const phone = useAppSelector(getRegistrationPhone);

    const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
    const [isVisibleHintModal, setIsVisibleHintModal] = useState(false);
    const [firstKeyPush, setFirstKeyPush] = useState(true);
    const [time, setTime] = useState(NEW_SMS_REQUEST_DELAY);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const restartTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            if (time === 1) {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            }

            setTime((value: number) => value - 1);
        }, 1000);
    };

    useEffect(() => {
        requestReferenceBySms(code);
        restartTimer();

        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            dispatch(smsVerificationStatusRequested(true));
            dispatch(smsServerErrorNotificationClosed());
            dispatch(smsVerificationFormUpdated({ code: '' }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess && code.length === 4) {
            requestOIDSmsVerification({
                code,
                options: {
                    mobile: true,
                },
            });
        }

        if (serverError) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                `Error ${serverErrorMessage}`,
            );
        }
    }, [
        code,
        requestOIDSmsVerification,
        queryRedirectParams.client_id,
        serverError,
        serverErrorMessage,
        formStatus,
    ]);

    const handleInputFinished = (value: string) => {
        if (value.length === SMS_INPUT_MAX_LENGTH) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                'Send',
            );
            dispatch(
                smsVerificationSubmit({
                    code: value,
                }),
            );
        }
    };

    const handleInputChange = (value: string) => {
        if (firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter SMS OTP',
                queryRedirectParams.client_id,
            );

            setFirstKeyPush(false);
        }

        if (serverError && !serverErrorMessage) {
            dispatch(smsVerificationServerErrorsReset());
        }

        dispatch(smsVerificationFormUpdated({ code: value }));

        if (value.length === SMS_INPUT_MAX_LENGTH) {
            handleInputFinished(value);
        }
    };

    const handleRepeatSms = () => {
        setTime(() => NEW_SMS_REQUEST_DELAY);

        dispatch(smsVerificationServerErrorsReset());
        dispatch(smsVerificationFormUpdated({ code: '' }));
        dispatch(smsVerificationStatusRequested(true));
        requestReferenceBySms(code);

        restartTimer();
        trackUserEvent('Auth Page', 'Click', 'Resend OTP', queryRedirectParams.client_id);
    };

    const phoneNumber = formatedMaskedPhoneNumber || phone;

    const renderTimer = () => {
        const minutes = `${Math.floor(time / 60)}`;
        const sec = `${time % 60}`;

        if (time > 0) {
            return `${minutes.length === 1 ? `0${minutes}` : minutes}:${
                sec.length === 1 ? `0${sec}` : sec
            }`;
        }

        return (
            <Button
                className={cn('repeat-button')}
                onClick={() => {
                    handleRepeatSms();
                }}
            >
                Запросить новый код
            </Button>
        );
    };

    const handleFocus = () => isIOS && setIsOpenKeyboard(true);

    const handleBlur = () => isIOS && setIsOpenKeyboard(false);

    return (
        <Fragment>
            <div className={cn({ open: isOpenKeyboard })}>
                <div className={cn('content')}>
                    <CatMessage />
                    <Typography.Title className={cn('title')} tag='h1' view='small' font='styrene'>
                        Отправил код,
                        <br /> ловите
                    </Typography.Title>
                </div>
                <div>
                    <CustomPinIndicator
                        onChange={(pinCode) => handleInputChange(pinCode)}
                        digitCounts={SMS_INPUT_MAX_LENGTH}
                        onFocus={() => handleFocus()}
                        onBlur={() => handleBlur()}
                        isClearOnError={true}
                        isValidationError={serverError}
                    />
                </div>
                <div className={cn('hint-wrapper')}>
                    <Typography.Text className={cn('hint')}>
                        Код отправлен на {phoneNumber.slice(0, 2)} ••• ••• {phoneNumber.slice(11)}
                    </Typography.Text>
                    <Typography.Text className={cn('hint')}>
                        Запросить повторно можно через
                    </Typography.Text>
                    <span className={cn('hint')}>{renderTimer()}</span>
                </div>
                <div className={cn('code-hint')}>
                    <Typography.Text
                        onClick={() => {
                            setIsVisibleHintModal(true);
                        }}
                    >
                        Не приходит код ?
                    </Typography.Text>
                </div>
            </div>
            <Modal open={isVisibleHintModal} wrapperClassName={cn('wrapper')} size='fullscreen'>
                <Hint
                    onClose={() => {
                        setIsVisibleHintModal(false);
                    }}
                />
            </Modal>
        </Fragment>
    );
};

export default MessengerBankSms;
