import React, { FC, useEffect } from 'react';
import loadable from '@loadable/component';
import { createCn } from 'bem-react-classname';

import Page from 'arui-private/core/page';

import PlateNotification from '#/src/components/app/app-browser/header/plate-notification/plate-notification';
import { AppLoader } from '#/src/components/ui/loader';
import { useAppDispatch, useAppSelector, useAuthFactorCheck } from '#/src/hooks';
import { useSetupEventBus } from '#/src/hooks/use-setup-event-bus';
import { getQueryRedirectParams, selectIsMobile } from '#/src/store/redux/app/selectors';
import { getAlfaPayCookies, getCustomDesignCookies } from '#/src/store/redux/custom-design/selectors';
import { customDesignParamSet } from '#/src/store/redux/custom-design/slice';
import { ApplicationState } from '#/src/store/types';

import Header from './header/header';

import './app-browser.css';

const VoipPanel = loadable(() => import('../../voip'));

const cn = createCn('custom-page');

interface Props {
    showHint: boolean;
    children?: React.ReactNode;
}

const AppBrowser: FC<Props> = ({ showHint, children }) => {
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(selectIsMobile);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isVoipEnabled = useAppSelector((state: ApplicationState) => state.App.isVoipEnabled);

    useAuthFactorCheck();
    useSetupEventBus();

    useEffect(() => {
        // Старая реализация (удалить в случаен переноса кук APay)
        const alfaPayCookies = getAlfaPayCookies(isMobile);

        if (alfaPayCookies.title && alfaPayCookies.backUrl) {
            dispatch(
                customDesignParamSet({
                    title: alfaPayCookies.title,
                    subtitle: alfaPayCookies.subtitle,
                    authorizationUrl: alfaPayCookies.authorizationUrl,
                    backUrl: alfaPayCookies.backUrl,
                }),
            );
        }

        const { title, subtitle, authorizationUrl, backUrl, client } = getCustomDesignCookies(isMobile);

        if (title) {
            let customDesignBackUrl = backUrl;

            if (client === 'alfaId' && queryRedirectParams.redirect_uri) {
                customDesignBackUrl = `${queryRedirectParams.redirect_uri}&error=auth_cancel`;
            }

            if(customDesignBackUrl) {
                dispatch(
                    customDesignParamSet({
                        title,
                        subtitle,
                        authorizationUrl,
                        backUrl: customDesignBackUrl,
                        client,
                    }),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLoader>
            <Page className={cn()} header={<Header showHint={showHint} data-testid='page' />}>
                <PlateNotification />
                {isVoipEnabled && <VoipPanel isMobile={isMobile} />}
                <div className={cn('content')}>{children}</div>
            </Page>
        </AppLoader>
    );
};

export default AppBrowser;
