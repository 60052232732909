import React, { useEffect } from 'react';
import { Outlet } from 'react-router';

import App from '#/src/components/app/app';
import AppMobile from '#/src/components/app/app-mobile';
import DesignCheck from '#/src/components/ui/design-check';
import { useAcrValues, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { browserDetect } from '#/src/lib/browser-detect';
import { init as initLogErrors } from '#/src/lib/log-errors';

import AppBrowser from './app-browser';
import addGlobalEventListeners from '#/src/lib/add-global-event-listeners';
import { getAlfaMetricsScript } from '#/src/server/scripts/alfa-metrica';
import { selectMetricsConfig } from '#/src/store/redux/app/selectors';

const Application = () => {
    const metricsConfig = useAppSelector(selectMetricsConfig)

    useAcrValues();

    useIsomorphicLayoutEffect(() => {
        browserDetect();
        addGlobalEventListeners();
        getAlfaMetricsScript(metricsConfig);
    }, [])

    useEffect(() => {
        initLogErrors();
    }, []);

    return (
        <DesignCheck
            defaultComponent={
                <AppBrowser showHint={false}>
                    <Outlet />
                </AppBrowser>
            }
            defaultMobileComponent={
                <AppBrowser showHint={false}>
                    <Outlet />
                </AppBrowser>
            }
            mobileComponent={
                <AppMobile>
                    <Outlet />
                </AppMobile>
            }
            corpComponent={
                <App>
                    <Outlet />
                </App>
            }
        />
    );
};

export default Application;
