import getMinimalBrowserVersions from './supported-browser-version';

export function browserDetect() {
    const userAgent = navigator.userAgent.toLowerCase();
    const vendor = navigator.vendor && navigator.vendor.toLowerCase();

    const isChrome = /Chrome/i.test(userAgent) && /Google Inc/i.test(vendor);
    const isSafari = /Safari/i.test(userAgent) && /Apple Computer/i.test(vendor);
    const isOpera = /Opr/i.test(userAgent) || /Opera/i.test(userAgent);
    const isWindows = /Windows/i.test(userAgent);
    const isMobileSafari = /iPad/i.test(userAgent) || /iPhone/i.test(userAgent);

    const agentClassName: string[] = [];
    let agentClassNameString = '';
    const bodyClassName = document.body.className;

    if (isChrome && !isOpera) {
        agentClassName.push('bd-chrome');
    }

    if (isSafari && !isOpera) {
        agentClassName.push('bd-safari');
    }

    if (isWindows) {
        agentClassName.push('bd-windows');
    }

    if (isMobileSafari) {
        agentClassName.push('bd-ios');
    }

    if (agentClassName.length) {
        agentClassNameString = agentClassName.join(' ');

        if (bodyClassName) {
            agentClassNameString = ` ${agentClassNameString}`;
        }

        document.body.className = bodyClassName + agentClassNameString;
    }
}

export function ieVersion(UA?: string) {
    if (!UA || typeof UA !== 'string') {
        return '';
    }

    let res = /trident\/.*rv:([0-9]+[.0-9]*)/.exec(UA);

    if (res && res.length > 1) {
        return `${parseInt(res[1], 10)}`;
    }

    if (UA.indexOf('trident/7') !== -1) {
        return '11';
    }

    res = /msie\s*([\d.]*)/gi.exec(UA);

    if (res && res.length > 1) {
        return `${parseInt(res[1], 10)}`;
    }

    return '';
}

export function isOldBrowser(UA: string) {
    let oldBrowser = false;

    const userAgent = UA.toLowerCase();

    if (!userAgent || typeof userAgent !== 'string') {
        return oldBrowser;
    }

    let version = '';
    const minimalVersion = getMinimalBrowserVersions();

    if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
        version = ieVersion(userAgent);
        oldBrowser = compareVersion(parseInt(`${version}`, 10), parseInt(minimalVersion.ie, 10)) < 0;
    } else if (/Chrome/i.test(userAgent)) {
        version = userAgent.substring(userAgent.indexOf('chrome') + 7);
        oldBrowser = compareVersion(parseInt(`${version}`, 10), parseInt(minimalVersion.chrome, 10)) < 0;
    } else if (/Safari/i.test(userAgent)) {
        version = userAgent.substring(userAgent.indexOf('version') + 8);
        oldBrowser = compareVersion(parseInt(`${version}`, 10), parseInt(minimalVersion.safari, 10)) < 0;
    } else if (/Firefox/i.test(userAgent)) {
        version = userAgent.substring(userAgent.indexOf('firefox') + 8);
        oldBrowser = compareVersion(parseInt(`${version}`, 10), parseInt(minimalVersion.firefox, 10)) < 0;
    }

    return oldBrowser;
}

function compareVersion(currentVersion: number, minimalSupportVersion: number): number {
    return currentVersion - minimalSupportVersion;
}

const getOS = (): string => {
    let os = 'Unknown';

    if (/Android/i.test(navigator.userAgent)) {
        os = 'android';
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        os = 'iOS';
    } else if (navigator.userAgent.indexOf('Win') !== -1) {
        os = 'Windows';
    } else if (navigator.userAgent.indexOf('Mac') !== 1) {
        os = 'MacOS';
    } else if (navigator.userAgent.indexOf('X11') !== -1) {
        os = 'UNIX';
    } else if (navigator.userAgent.indexOf('Linux') !== -1) {
        os = 'Linux';
    }

    return os;
};

export const getBrowser = (): string => {
    let browser = 'Unknown';

    if (navigator.userAgent.indexOf('YaBrowser') !== -1) {
        browser = 'yaBrowser';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        browser = 'firefox';
    } else if (navigator.userAgent.indexOf('Opera') !== -1) {
        browser = 'opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        browser = 'googleChrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        browser = 'safari';
    } else if (navigator.userAgent.indexOf('Edge') !== -1) {
        browser = 'microsoftEdge';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
        browser = 'internetExplorer';
    }

    return browser;
};

export const getBrowserAndOS = () => {
    const browser = getBrowser();
    const os = getOS();

    return { browser, os };
};
