import { useLocation } from 'react-router';

import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { checkBrowserSecret } from '#/src/lib/check-browser-secret';
import { clientErrorLog } from '#/src/lib/client-logger';
import getIsPincodeAvailable from '#/src/lib/get-is-pincode-available';
import { Routes } from '#/src/models';
import { useGetAkeyDeeplinkMutation, useRequestAkeyDeeplinkMutation } from '#/src/store/api/akey-api';
import { usePincodeActivateMutation } from '#/src/store/pincode/api';
import { selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import {
    isClickDesign,
    selectBrowserSecretPublicKey,
    selectIsAKeyEnabled,
    selectIsAKeyVisited,
    selectIsBrowserSecretEnabled,
    selectIsMobile,
    selectIsWebAuthnEnabled,
} from '#/src/store/redux/app/selectors';
import { isAkeyVisitedSet, preloaderHidden, preloaderShown } from '#/src/store/redux/app/slice';
import { selectHasPincodeActivateData, selectIsPincodeVisited } from '#/src/store/pincode/selectors';
import { pincodeUpdated, pincodeVisitedSet } from '#/src/store/pincode/slice';
import { getWebAuthnFlag } from '#/src/store/redux/webauthn/selectors';
import { FixedCacheKeys } from '../store/constants';
import { redirectTo } from '#/src/lib/redirect';

export const useAuthFactorCheck = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const [getDeeplink] = useGetAkeyDeeplinkMutation();
    const [requestDeeplink] = useRequestAkeyDeeplinkMutation();
    const [requestPincodeActivate] = usePincodeActivateMutation({
        fixedCacheKey: FixedCacheKeys.pincodeActivate,
    });

    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const isAKeyEnabled = useAppSelector(selectIsAKeyEnabled);
    const isAKeyVisited = useAppSelector(selectIsAKeyVisited);

    const isMobile = useAppSelector(selectIsMobile);

    const isWebAuthn = getWebAuthnFlag();
    const isWebAuthnEnabled = useAppSelector(selectIsWebAuthnEnabled);
    const isBrowserSecretEnabled = useAppSelector(selectIsBrowserSecretEnabled);
    const browserSecretPublicKey = useAppSelector(selectBrowserSecretPublicKey);

    const clickDesign = useAppSelector(isClickDesign);
    const isPincodeAvailable = useAppSelector(getIsPincodeAvailable);
    const isPincodeVisited = useAppSelector(selectIsPincodeVisited);
    const hasPincodeActivateData = useAppSelector(selectHasPincodeActivateData);

    const setBrowserSecrets: () => Promise<void> = async () => {
        const newBrowserSecretData = await checkBrowserSecret(browserSecretPublicKey);

        if (newBrowserSecretData) {
            dispatch(pincodeUpdated(newBrowserSecretData));
        }
    };

    useIsomorphicLayoutEffect(() => {
        (async () => {
            switch (pathname) {
                case Routes.LOGIN:
                case Routes.CARD_ACCOUNT:
                    if (isBrowserSecretEnabled && clickDesign && browserSecretPublicKey) {
                        await setBrowserSecrets();
                    }

                    if (isAKeyAvailable && isAKeyEnabled && !isAKeyVisited) {
                        const result = await requestDeeplink();

                        if (result.data?.app_start_deeplink) {
                            redirectTo(Routes.AKEY)
                            dispatch(isAkeyVisitedSet(true));
                        } else if (isPincodeAvailable) {
                            dispatch(preloaderShown());
                            const response = await requestPincodeActivate();
                            if (response.data?.attemptsLeft) {
                                redirectTo(Routes.PINCODE);
                            } else {
                                dispatch(preloaderHidden());
                            }
                        } else {
                            dispatch(preloaderHidden());
                        }
                    } else if (!isAKeyAvailable && isMobile && isWebAuthn && isWebAuthnEnabled) {
                        redirectTo(Routes.WEBAUTHN);
                    } else if (isPincodeAvailable) {
                        const response = await requestPincodeActivate();
                        if (response.data?.attemptsLeft) {
                            redirectTo(Routes.PINCODE);
                        } else {
                            dispatch(preloaderHidden());
                        }
                    } else {
                        dispatch(preloaderHidden());
                    }
                    break;

                case Routes.AKEY:
                    if (isBrowserSecretEnabled && clickDesign && browserSecretPublicKey) {
                        await setBrowserSecrets();
                        dispatch(isAkeyVisitedSet(true));
                    }

                    if (!hasPincodeActivateData && isPincodeAvailable) {
                        try {
                            await requestPincodeActivate();
                        } catch (error) {
                            const err = new Error(`Pincode activate error: ${error}`);

                            clientErrorLog(err);
                        }
                    }
                    break;

                case Routes.WEBAUTHN:
                    if (!isWebAuthn && !isWebAuthnEnabled) {
                        redirectTo(Routes.LOGIN);

                        return;
                    }

                    if (isPincodeAvailable) {
                        await requestPincodeActivate();
                    }

                    if (isBrowserSecretEnabled && clickDesign && browserSecretPublicKey) {
                        await setBrowserSecrets();
                        dispatch(isAkeyVisitedSet(true));
                    }
                    break;

                case Routes.PINCODE:
                    if (isPincodeAvailable) {
                        if (!hasPincodeActivateData) {
                            try {
                                const response = await requestPincodeActivate();

                                if (response) {
                                    if (isAKeyAvailable && isAKeyEnabled) {
                                        getDeeplink();
                                    }
                                }
                            } catch (error) {
                                const err = new Error(`Pincode activate error: ${error}`);
                                await clientErrorLog(err);
                            }

                            dispatch(pincodeVisitedSet(true));
                        }
                    } else if (!isPincodeVisited) {
                        redirectTo(Routes.LOGIN);
                    }
                    dispatch(preloaderHidden());
                    break;

                case Routes.JWT_AUTH:
                    if (isBrowserSecretEnabled && clickDesign && browserSecretPublicKey) {
                        await setBrowserSecrets();
                    }
                    dispatch(preloaderHidden());
                    break;

                default:
                    dispatch(preloaderHidden());
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
};
