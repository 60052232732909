import { createSelector } from 'reselect';

import getCookie from '#/src/lib/get-cookie';
import { AuthType, CookiesName } from '#/src/models';
import { ApplicationState } from '#/src/store/types';
import { isClickDesign as selectIsClickDesign, selectIsMobile } from '#/src/store/redux/app/selectors';

import { selectAuthorizationAuthType, selectAuthorizationPcUserId } from '../authorization/selectors';

export const getAkeyState = (state: ApplicationState) => state.AKeyAuthorization;

export const selectAkeyDeeplink = createSelector([getAkeyState], (akey) => akey.deeplink);
export const selectAkeyError = createSelector([getAkeyState], (akey) => akey.error);

/** Получить значение куки authType. */
export const getAuthType = (): string => {
    // Скрываем на время SSR
    if (typeof document === 'undefined') return '';
    const authType = getCookie(CookiesName.authType);

    return authType || '';
};

/** Получить значение куки pcUserID. */
export const getPcUserId = (): string => {
    // Скрываем на время SSR
    if (typeof document === 'undefined') return '';
    const pcUserID = getCookie(CookiesName.pcUserID);

    return pcUserID || '';
};

/** Функция получения флага доступности AKey для пользователя. */
export const selectIsAKeyAvailable = createSelector(
    [selectIsClickDesign, selectAuthorizationAuthType, selectAuthorizationPcUserId, selectIsMobile],
    (isClickDesign, authType, pcUserId, isMobile) => {
        const isAKeyAuthType =
            authType === AuthType.AKey ||
            authType === AuthType.Capsule ||
            authType === AuthType.Voskhod ||
            authType === AuthType.VoskhodApp;

        return isMobile && !!pcUserId && isAKeyAuthType && isClickDesign;
    },
);
