import { AcrValues, Routes } from '#/src/models';
import { QueryRedirectParams } from '#/src/types/interfaces';

export const checkAcrValues = (route: string, queryRedirectParams: QueryRedirectParams, options: Record<string, any> ): string => {
    if (Routes.LOGIN === route) {
        if (options.isWhiteListAdminClientId) {
            return AcrValues.employees;
        } if (options.isCorporateClientId) {
            return '';
        }

        return AcrValues.username;
    }

    if (Routes.AD_USERNAME_PASSWORD === route && queryRedirectParams.registration === 'true') {
        return `${AcrValues.card}:${AcrValues.sms}`;
    }

    if (Routes.AD_USERNAME_PASSWORD === route && queryRedirectParams.registration !== 'true') {
        return AcrValues.adUsernamePassword
    }

    if (Routes.CORP_USERNAME === route) {
        return '';
    }

    if (Routes.CARD_ACCOUNT === route || Routes.RESTORE === route) {
        return `${AcrValues.card}:${AcrValues.sms}`;
    }

    if (Routes.PHONE_LOGIN === route) {
        if (queryRedirectParams.acr_values !== `${AcrValues.phone}:${AcrValues.sms}`) {
            return `${AcrValues.card}:${AcrValues.sms}`
        }

        return '';
    }

    if (Routes.PHONE_AUTH === route) {
        return `${AcrValues.phoneAuth}:${AcrValues.sms}`;
    }

    if (Routes.FULLNAME_REGISTRATION === route) {
        return queryRedirectParams.acr_values
            ? queryRedirectParams.acr_values.replace( 'full_name', AcrValues.fullname )
            : `${AcrValues.fullname}:${AcrValues.sms}`;
    }

    if (Routes.PASSCODE === route) {
        return `${AcrValues.passcode}:${AcrValues.sms}`;
    }

    if (Routes.TEMP_PASSWORD === route) {
        return `${AcrValues.tempPassword}:${AcrValues.sms}`;
    }

    return queryRedirectParams.acr_values;
}
