import React, { FC } from 'react';

import { Spinner } from '@alfalab/core-components/spinner';

import { PasscodeType } from '#/src/models';

import AlphaNumericPasscode from '../alpha-numeric';
import NumericPassCode from '../numeric';
import PatternLockPasscode from '../pattern-lock';

interface Props {
    passcodeType: string;
}

export const SwitchPasscodeType: FC<Props> = ({ passcodeType }) => {
    switch (passcodeType) {
        case PasscodeType.Numeric:
            return <NumericPassCode />;
        case PasscodeType.GraphicPattern:
            return <PatternLockPasscode />;
        case PasscodeType.Alphanumeric:
            return <AlphaNumericPasscode />;
        default:
            return <Spinner visible={true} size='m' />;
    }
};
