import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Confirmation, useConfirmation } from '@alfalab/core-components/confirmation';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';

import AssistantDialog from '#/src/components/ui/assistant-dialog';
import SmsHint from '#/src/components/ui/sms-hint';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import hasCloseSms from '#/src/lib/acr-actions-for-sms';
import { trackUserEvent } from '#/src/lib/analitycs';
import { SMS_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import { maskPhone } from '#/src/lib/mask-phone';
import { FormStatus } from '#/src/models';
import {
    useRequestOIDSmsVerificationMutation,
    useRequestReferenceBySmsMutation,
} from '#/src/store/api/sms-verification-api';
import {
    formatMaskedPhoneNumber,
    getQueryRedirectParams,
    hasSmsModalCloser,
    isInvestmentsNewDesign,
    selectGetDeviceOSName,
    selectIsMobileNewVersion,
    selectIsMobileOnboardingDesignVariant,
} from '#/src/store/redux/app/selectors';
import { getRegistrationPhone } from '#/src/store/redux/registration/selectors';
import {
    selectSmsCode,
    selectSmsFormStatus,
    selectSmsRequestStatus,
    selectSmsServerError,
    selectSmsServerErrorMsg,
} from '#/src/store/redux/sms-verification/selectors';
import {
    smsServerErrorNotificationClosed,
    smsVerificationFormUpdated,
    smsVerificationServerErrorsReset,
    smsVerificationStatusRequested,
    smsVerificationSubmit,
} from '#/src/store/redux/sms-verification/slice';

import './sms-v2-mobile.css';

const cn = createCn('sms-v2-mobile');
const SmsV2Mobile: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [requestOIDSmsVerification] = useRequestOIDSmsVerificationMutation();
    const [requestReferenceBySms] = useRequestReferenceBySmsMutation();

    const formStatus = useAppSelector(selectSmsFormStatus);
    const requestStatus = useAppSelector(selectSmsRequestStatus);
    const code = useAppSelector(selectSmsCode);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const serverError = useAppSelector(selectSmsServerError);
    const serverErrorMessage = useAppSelector(selectSmsServerErrorMsg);
    const formatedMaskedPhoneNumber = useAppSelector(formatMaskedPhoneNumber);
    const phone = useAppSelector(getRegistrationPhone);
    const isMobileOnboardingDesignVariant = useAppSelector(selectIsMobileOnboardingDesignVariant);
    const investmentsNewDesign = useAppSelector(isInvestmentsNewDesign);
    const smsModalHasCloser = useAppSelector(hasSmsModalCloser);
    const deviceOS = useAppSelector(selectGetDeviceOSName);
    const isMobileNewVersion = useAppSelector(selectIsMobileNewVersion);

    const { confirmationState, confirmationScreen, setConfirmationState, setConfirmationScreen } =
        useConfirmation();

    const phoneNumber = formatedMaskedPhoneNumber
        ? `${formatedMaskedPhoneNumber.replace(/000 000/gi, '••• •••')}`
        : maskPhone(phone);

    useEffect(() => {
        requestReferenceBySms(code);

        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);

        return () => {
            dispatch(smsVerificationStatusRequested(true));
            dispatch(smsServerErrorNotificationClosed());
            dispatch(smsVerificationFormUpdated({ code: '' }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serverError) setConfirmationState('CODE_ERROR');
        else setConfirmationState('INITIAL');
    }, [setConfirmationState, serverError]);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess && code.length === 4) {
            setConfirmationState('CODE_CHECKING');
            requestOIDSmsVerification({
                code,
                options: {
                    mobile: true,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, formStatus]);

    useEffect(() => {
        if (serverError) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                `Error ${serverErrorMessage}`,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverError]);

    const handleInputFinished = (value: string) => {
        if (value.length === SMS_INPUT_MAX_LENGTH && requestStatus) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                'Send',
            );

            if (serverError || serverErrorMessage) {
                dispatch(smsVerificationServerErrorsReset());
            }

            dispatch(smsVerificationFormUpdated({ code: value }));
            dispatch(
                smsVerificationSubmit({
                    code: value,
                }),
            );
        } else {
            setConfirmationState('CODE_ERROR');
        }
    };

    const handleRepeatSms = () => {
        if (serverError) {
            dispatch(smsVerificationServerErrorsReset());
        }
        if (!requestStatus) {
            dispatch(smsVerificationStatusRequested(true));
        }
        if (code) {
            dispatch(smsVerificationFormUpdated({ code: '' }));
        }
        trackUserEvent('Auth Page', 'Click', 'Resend OTP', queryRedirectParams.client_id);
        requestReferenceBySms(code);

        // Чтобы компонента блокировала повторную отправку смс после его нажатия
        setTimeout(() => {
            setConfirmationState('INITIAL');
        }, 1000);
    };

    const handleClose = () => {
        if (hasCloseSms(queryRedirectParams.acr_values)) {
            dispatch(smsVerificationFormUpdated({ code: '' }));
            navigate(-1);
        }
    };

    const showTitle = () => {
        const nbsp = String.fromCharCode(160);
        const signTitle = `Введите${nbsp}код${nbsp}из${nbsp}сообщения`;
        const assistantDialogParams = () => {
            if (deviceOS === 'iOS' && isMobileNewVersion) {
                return {
                    text: 'Спасибо! Отправили код, ловите\u00A0👆',
                    hideIcon: true,
                };
            }

            return {
                text: 'Спасибо! Отправил код, ловите\u00A0👆',
            };
        };

        if (isMobileOnboardingDesignVariant) {
            return <AssistantDialog {...assistantDialogParams()} />;
        }

        if (investmentsNewDesign) {
            const hideIcon = !!queryRedirectParams.light_flow;

            return <AssistantDialog text='Спасибо! Отправил код, ловите' hideIcon={hideIcon} />;
        }

        return signTitle;
    };
    const handleHintButtonClick = () => {
        setConfirmationScreen('INITIAL');
        setConfirmationState('INITIAL');
    };

    return (
        <ModalMobile
            open={true}
            usePortal={false}
            className={cn()}
            wrapperClassName={cn('wrapper')}
            onClose={handleClose}
        >
            <ModalMobile.Header className={cn('header')} hasCloser={smsModalHasCloser} />
            <ModalMobile.Content className={cn('content')} flex={true}>
                <Confirmation
                    className={cn('confirmation')}
                    alignContent={
                        isMobileOnboardingDesignVariant || investmentsNewDesign ? 'left' : 'center'
                    }
                    phone={phoneNumber}
                    screen={confirmationScreen}
                    state={confirmationState}
                    onChangeState={setConfirmationState}
                    onChangeScreen={setConfirmationScreen}
                    onInputFinished={handleInputFinished}
                    onSmsRetryClick={handleRepeatSms}
                    countdownDuration={60_000}
                    clearCodeOnError={false}
                    requiredCharAmount={SMS_INPUT_MAX_LENGTH}
                    texts={{
                        title: showTitle(),
                        codeError: serverErrorMessage,
                    }}
                    getScreensMap={(screensMap) => ({
                        ...screensMap,
                        // eslint-disable-next-line react/no-unstable-nested-components
                        HINT: () => <SmsHint mobile={true} onButtonClick={handleHintButtonClick} />,
                    })}
                />
            </ModalMobile.Content>
        </ModalMobile>
    );
};

export default SmsV2Mobile;
