export type AlfaMetricsConfig = {
    alfaMetricsUrl: string;
    endpointUrl: string;
    appId: string;
};

export function getAlfaMetricsScript(config: AlfaMetricsConfig) {
    const {
        alfaMetricsUrl,
        endpointUrl,
        appId,
    } = config;


    const scriptText = `        
          (function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
            p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
            };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
            n.src=w;g.parentNode.insertBefore(n,g)}}(window, document, 'script',
            '${alfaMetricsUrl}', 'sp'));`;

    const metricsScript = document.createElement('script');
    metricsScript.text = scriptText;
    document.head.append(metricsScript);
    // Initialise a tracker
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sp(
        'newTracker',
        'ab',
        endpointUrl,
        {
            appId,
            platform: 'web',
            discoverRootDomain: true
        });
}
