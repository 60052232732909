export enum FormStatus {
    ValidationError = 'VALIDATION_ERROR',
    ValidationSuccess = 'VALIDATION_SUCCESS',
    SubmitError = 'SUBMIT_ERROR',
    SubmitProcess = 'SUBMIT_PROCESS',
    SubmitSuccess = 'SUBMIT_SUCCESS',
    SubmitExceeded = 'SUBMIT_EXCEEDED',
    SubmitFatalError = 'SUBMIT_FATAL_ERROR',
    Initial = 'INITIAL',
}

export const AcrValues = {
    account: 'card_account',
    adUsernamePassword: 'ad-username-password',
    akey: 'akey',
    card: 'card_account',
    corpUsername: 'corp-username',
    login: 'login',
    registration: 'registration',
    sms: 'sms',
    corpSms: 'corp-sms',
    username: 'username',
    fullname: 'fullname',
    notClientSms: 'not-client-sms',
    employees: 'employees',
    phone: 'phone',
    phoneNumber: 'phone_number',
    phoneAuth: 'phone_auth',
    pincode: 'pincode',
    mobileJwt: 'mobile-jwt',
    passcode: 'passcode',
    webAuthn: 'webAuthn',
    tempPassword: 'temp-password',
    biom: 'biom',
};

export const KorusClientIds = {
    corpKorusApi: ['corp-korus-api', 'ssoHash'],
    moedelo: ['moedelo', 'mdHash'],
    corpExternalPotok: ['corp-external-potok', 'cus_id'],
};

export const CookiesName = {
    acl: 'acl',
    browserId: 'browserId',
    changedFlow: 'changedFlow',
    authType: 'auth_type',
    pcUserID: 'pc_user_id',
    passportSessionId: 'passport_session_id',
    webAuthnFlag: 'auth_type_webauthn',
    alfaPayMerchantTitle: 'alfapay_merchant_title',
    alfaPayMerchantSubtitle: 'alfapay_merchant_subtitle',
    alfapayAuthorizationUrl: 'alfapay_authorization_url',
    alfaPayMerchantBackUrl: 'alfapay_merchant_back_url',
    authTypePincode: 'auth_type_pincode',
    deviceAppId: 'DEVICE_APP_ID',
    forgottenPasscode: 'forgottenPasscode',
    customDesignTitle: 'custom_design_title',
    customDesignSubtitle: 'custom_design_subtitle',
    customDesignAuthorizationUrl: 'custom_design_authorization_url',
    customDesignBackUrl: 'custom_design_back_url',
    customDesignClient: 'custom_design_client',
};

export const LocalStorageName = {
    browserSecret: 'browser_secret',
    browserSecretDate: 'browser_secret_date',
    xUserInfo: 'x-user-info',
};

export enum RegistrationType {
    Login = 'LOGIN',
    Phone = 'PHONE',
    Card = 'CARD',
    Account = 'ACCOUNT',
    FastAuth = 'FASTAUTH',
    Name = 'NAME',
}

export const PasscodeType = {
    Numeric: 'NUMERIC',
    Alphanumeric: 'ALPHANUMERIC',
    GraphicPattern: 'GRAPHIC_PATTERN',
} as const;

export const AuthorizationType = {
    Phone: 'PHONE',
    CardAccount: 'CARD_ACCOUNT',
    Card: 'CARD',
    Account: 'ACCOUNT',
    Login: 'LOGIN',
    Name: 'NAME',
};

export const ValidationType = {
    Login: 'login',
    Password: 'password',
    NewPassword: 'newPassword',
    RepeatedNewPassword: 'repeatedNewPassword',
};

export const AlfaDirect = {
    redirectUrl: 'https://alfa-mobile.alfabank.ru/ALFAJMB/openid/authorize',
};

export const Routes = {
    AD_USERNAME_PASSWORD: '/ad-username-password',
    CARD_ACCOUNT: '/card_account',
    CARD_ACCOUNT_NOT_CLIENT: '/card_account_not_client',
    CORP_USERNAME: '/corp-username',
    ERROR: '/error',
    FAST_REGISTRATION: '/login',
    FINISH_SIGNIN: '/finish_signin',
    LOGIN: '/username',
    PHONE_LOGIN: '/phone',
    PHONE_AUTH: '/phone_auth',
    OFFER: '/offer',
    FULL_NAME_NOT_CLIENT: '/full_name_not_client',
    FULLNAME_REGISTRATION: '/fullname',
    PASSWORD: '/password',
    REGISTRATION: '/registration',
    SMS: '/sms',
    CORP_SMS: '/corp-sms',
    EXPLANATION: '/explanation',
    DIRECT_DEMO_USER: '/direct-demo',
    UPDATE: '/update',
    FORGOT_LOGIN: '/forgot-login',
    FORGOT_PASSWORD: '/forgot-password',
    TEMP_PASSWORD_BY_SMS: '/temp-password-by-sms',
    EMPLOYEES: '/employees',
    AZON: '/azon',
    RESTORE: '/restore',
    JWT_AUTH: '/jwt_auth',
    AKEY: '/akey',
    PASSCODE: '/passcode',
    NEW_PASSCODE: '/new-passcode',
    PINCODE: '/pincode',
    WEBAUTHN: '/webauthn',
    WEBAUTHN_REG: '/webauthn-reg',
    TEMP_PASSWORD: '/temp-password',
    BIOMETRY: '/biom',
} as const;

export type RoutePathKey = keyof typeof Routes;
export type RoutePath = (typeof Routes)[RoutePathKey];
export type RouteHandler = any;

export const NotificationStatus = {
    advices: 'advices',
    warnings: 'warnings',
};

export const reason = {
    temporary: 'password_is_temporary',
    expired: 'password_expired',
};

export const PasswordTypes = {
    permanentPass: 'PERMANENT_PASSWORD',
    passForLogin: 'PASSWORD_FOR_LOGIN',
    passWithConfirmLogin: 'PASSWORD_WITH_CONFIRM_LOGIN',
};

export const Themes = {
    whiteTheme: 'alfa-on-white',
    defaultTheme: 'alfa-on-color',
} as const;

export const ClientIds = {
    adfClickWeb: 'click-web-adf',
    adirectWebpro: 'adirect-webpro',
    alfaformDcDt2ao: 'alfaform-dc-dt2ao',
    clickUpsaleCardsWeb: 'click-upsale-cards-web',
    clickWeb: 'click-web',
    clickWebV2: 'click-web-v2',
    corpAlbo: 'corp-albo',
    corpPassport: 'corp-passport',
    ecoClickWeb: 'click-eco-web',
    investments: 'wealth-management-nc',
    investmentsMobile: 'adirect-app',
    lkx5Web: 'lkx5-web',
    messengerTelegramBank: 'xm2-web',
    messengerVkBank: 'vk-web',
    mobileApp: 'mobile-app',
    newclickUzesiaWeb: 'newclick-uzesia-web',
    newClickWeb: 'newclick-web',
    relendClickWeb: 'click-relend-web',
    refinWeb: 'refin-web',
    upsaleVerificationClickWeb: 'click-upsale-verification-web',
    retailUpsaleCardsWeb: 'retail-upsale-cards-web',
    retailUpsaleCardsAnketsWeb: 'retail-upsale-cards-ankets-web',
    whiteListAdmin: 'corp-white-list-admin',
    X5: 'X5-web',
    passportWebDemoWeb: 'passport-webdemo-web',
    alfaIdAuthorize: 'alfa-id-authorize',
    lkGreenrobleWeb: 'lk-greenroble-web',
};

export const DefaultLoginAttemptLimit = 9999;
export const oneDayInMs = 1000 * 60 * 60 * 24;
export const DEMO_PHONE_NUMBER = '+000112233445';

export const iosScanType = {
    cardIO: 'cardIO',
    smartIDReader: 'smartIDReader',
};

export const AppVersionsWithScanner: Record<string, string> = {
    iOS: '11.2',
    Android: '11.12.0',
};

// TODO - по возможности переделать на более специфичный тип
export const AppVersionsWithNewUi: Record<string, string> = {
    iOS: '11.6',
    Android: '11.15.0',
};

export const AppVersionsWithSupportInfoScreen: Record<string, string> = {
    iOS: '13.14',
    Android: '12.06',
};

export const AppVersionsWithNativeCallBank: Record<string, string> = {
    iOS: '13.14',
    Android: '12.15',
};

export const AppVersionsWithNewInvestmentsDesign: Record<string, string> = {
    iOS: '2.18.0',
    Android: '2.15.0',
};

export const AppVersionsWithNewMobileDesign: Record<string, string> = {
    iOS: '12.22.0',
};

export const AppVersionsWithBiometryAuth: Record<string, string> = {
    iOS: '13.10',
    Android: '12.07',
};

export const DeviceOSNames = ['iOS', 'Android'];

export const DeviceOSVersionWithNewUi: Record<string, string> = {
    iOS: '0',
    Android: '24',
};

export const AppVersionsNeedUpdate: Record<string, string> = {
    iOS: '12.13.99',
    Android: '11.49.99',
};

export const ButtonNames = {
    continue: 'Продолжить',
    changeAccount: 'Изменить номер карты или счёта',
    scanCard: 'Сканировать карту',
    backToCardInput: 'Вернуться к вводу карты',
    getCard: 'Получить карту',
    becomeClient: '🚀 Стать клиентом',
    gosUslugi: 'Получить доступ через Госуслуги',
    restorePassword: 'Восстановить пароль',
    logIn: 'Войти',
    back: 'Назад',
    forward: 'Вперёд',
    getLoginPass: 'Не знаю логин и пароль',
    enter: 'Войти',
    noPass: 'Нет пароля',
    letsGo: 'Вперёд',
    alternativeLogin: 'Войти другим способом',
    iDontHaveLoginOrPassword: 'У меня нет логина или пароля',
    goBack: 'Вернуться',
    tryAgain: 'Попробовать ещё раз',
} as const;

export const AlboDigestLink = 'http://alfacorp.digital/redigestnew';
export const AlboDemoLink =
    'https://link.alfabank.ru/webclient/pages/static/iFrames/demo/index.html';
export const HelpLink = 'https://alfabank.ru/help/t/corp/alfaforbusiness/';

export const ChangeLeaderLink = 'https://alfabank.ru/sme/change-eio/?platformId=nib_change_eio&utm_source=nib&utm_campaign=smena_eio';
export const OpenBusinessAccountLink =
    'https://alfabank.ru/sme/shortpage/?platformId=nib_sme_shortpage&utm_source=nib&utm_campaign=sme_shortpage';
export const BusinessRegistrationLink =
    'https://alfabank.ru/sme/start/?platformId=nib_sme_start&utm_source=nib&utm_campaign=sme_start';
export const ServerErrors = {
    PHONE_REQUIRED: 'PHONE_REQUIRED',
    PHONE_NOT_FOUND: 'PHONE_NOT_FOUND',
    NOT_PHYSICAL_PERSON: 'NOT_PHYSICAL_PERSON',
    MORE_THEN_ONE_USER_FOUND: 'MORE_THEN_ONE_USER_FOUND',
    ID_TYPE_NOT_FOUND: 'ID_TYPE_NOT_FOUND',
    USER_IS_CLIENT: 'USER_IS_CLIENT',
    CARD_IS_NOT_READY: 'CARD_IS_NOT_READY',
    APP_VERSION_MUST_BE_ABOVE: 'APP_VERSION_MUST_BE_ABOVE',
    OS_OR_APP_VERSION_NOT_FOUND: 'OS_OR_APP_VERSION_NOT_FOUND',
    PASSCODE_VERIFICATION_IS_FAILED: 'PASSCODE_VERIFICATION_IS_FAILED',
    TEMP_PASSWORD_VERIFICATION_IS_FAILED: 'TEMP_PASSWORD_VERIFICATION_IS_FAILED',
    TEMP_PASSWORD_IS_NOT_SET: 'TEMP_PASSWORD_IS_NOT_SET',
    CHANNEL_BLOCKED: 'CHANNEL_BLOCKED',
    CHANNEL_STOPPED: 'CHANNEL_STOPPED',
    PHOTO_VERIFICATION_IS_FAILED: 'PHOTO_VERIFICATION_IS_FAILED',
};

export const QRStatus = {
    FirstConnection: 'FIRST_CONNECTION',
    Open: 'OPEN',
    Closed: 'CLOSED',
    NotConnected: 'NOT_CONNECTED',
    Pending: 'PENDING',
    Timeout: 'TIMEOUT',
};

export const MetricsClientId: Record<string, string> = {
    [ClientIds.newClickWeb]: 'CLICK',
    [ClientIds.clickWeb]: 'CLICK',
    [ClientIds.adfClickWeb]: 'CLICK',
    [ClientIds.mobileApp]: 'MOBILE',
    [ClientIds.corpPassport]: 'CORP',
};

export const MetricsRoute: Record<string, string> = {
    [Routes.PHONE_AUTH]: 'Auth Phone Number Screen',
    [Routes.CARD_ACCOUNT]: 'Auth Card Account Screen',
    [Routes.SMS]: 'Auth SMS Verification Screen',
    [Routes.LOGIN]: 'Auth Username Screen',
    [Routes.PASSWORD]: 'Auth Password Screen',
    [Routes.FINISH_SIGNIN]: 'Finish auth Screen',
    [Routes.PASSCODE]: 'Auth Passcode Screen',
    [Routes.AKEY]: 'AKey auth Screen',
    [Routes.TEMP_PASSWORD]: 'Auth TempPassword Screen',
};

export const clientIdRedirect: Record<string, string> = {
    [ClientIds.adfClickWeb]: 'newclick-web',
};

export const nativeEventStatus = {
    buttonPressed: 'buttonPressed',
    loginFailed: 'loginFailed',
};

export const AuthType = {
    AKey: 'akey',
    Capsule: 'akey_app',
    Voskhod: 'voskhod',
    VoskhodApp: 'voskhod_app',
};

export const BrowserSecretPublicKey =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlIU3x8TQtjipH595qgz59G+raaSyYAocTQ5o/iJ0bc7xzQBcV+AdxxZN8mggTLA2DYAQn9S3Ouo4j8XBQwXvrBP4LCHVW02r6O2uJhBloxr1A50hQ4DO7k72PZCONxrYx+pPZhxsRvShUBNWeH3CA7v7iGb74mPobQeEp6cYaR/pH0TCb7u59iIfS12YW5PgB11xFzjSV0IB1fyx2LbxaQKYTy8BXa+GPXq+SvfTqMtxjrcqrmndjaUgWtT6vXZwIJ36N4K/rlYmMHBEzQgnd3pij6I34EU2lS0ArxmG6raUVWITW0Zuh9YxxDKYS9bcheuaMTeoULQ7eXMWLEWPtQIDAQAB';
