import React, { FC } from 'react';

import { BottomSheet } from '@alfalab/core-components/bottom-sheet';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { AuthCellMobile } from '#/src/components/ui/auth-cell';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import {
    isAppVersionHasBiometryAuth,
    selectGetDeviceOSName,
} from '#/src/store/redux/app/selectors';
import { selectBiometryIsBiometryAuth } from '#/src/store/redux/biometry';
import { scanFaceModule } from '#/src/store/redux/biometry/fetchAuhorizeBiometry';
import {
    selectIsAltAuthModalOpen,
    selectIsPasscodeAuth,
    selectPasscodeTypes,
} from '#/src/store/redux/passcode/selectors';
import { isAltAuthModalOpenSet } from '#/src/store/redux/passcode/slice';

import { cn } from '..';

const BsAltLogin: FC = () => {
    const dispatch = useAppDispatch();

    const isAltAuthModalOpen = useAppSelector(selectIsAltAuthModalOpen);
    const passcodeTypes = useAppSelector(selectPasscodeTypes);
    const osName = useAppSelector(selectGetDeviceOSName);
    const isPasscodeAuth = useAppSelector(selectIsPasscodeAuth);
    const isBiometryAuth = useAppSelector(selectBiometryIsBiometryAuth);
    const isSupportBiometryAuth = useAppSelector(isAppVersionHasBiometryAuth);

    const scanFace = () => {
        dispatch(scanFaceModule());
    };

    return (
        <BottomSheet
            open={isAltAuthModalOpen}
            className={cn('bottomsheet')}
            title={
                <Typography.TitleMobile view='small' color='primary' tag='div'>
                    Другие способы входа&nbsp;👇
                </Typography.TitleMobile>
            }
            onClose={() => dispatch(isAltAuthModalOpenSet(false))}
            hasCloser={true}
            swipeable={true}
        >
            {isPasscodeAuth && (
                <AuthCellMobile
                    authType={passcodeTypes.length === 0 ? 'passcode' : 'new-passcode'}
                />
            )}
            {isBiometryAuth && isSupportBiometryAuth && (
                <AuthCellMobile authType='biometry' onSpecialClick={scanFace} />
            )}
            <AuthCellMobile authType='temp-password' />
            {osName === 'iOS' && <Gap size={20} direction='vertical' />}
        </BottomSheet>
    );
};

export default BsAltLogin;
