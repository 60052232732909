import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SystemMessage } from '@alfalab/core-components/system-message';
import { InternetOffMIcon } from '@alfalab/icons-glyph/InternetOffMIcon';
import { ScrewdriverPaintBrushMIcon } from '@alfalab/icons-glyph/ScrewdriverPaintBrushMIcon';

import { Routes } from '#/src/models';

import { cn } from '..';

const statusData = {
    online: {
        title: 'Не получилось загрузить',
        subtitle: 'Уже знаем, в чём дело, и\u00A0чиним. Попробуйте\u00A0зайти позже',
        icon: <ScrewdriverPaintBrushMIcon />,
    },
    offline: {
        title: 'Потеряли связь с\u00A0вами',
        subtitle: 'Проверьте подключение к\u00A0интернету и попробуйте снова',
        icon: <InternetOffMIcon />,
    },
};

export const ServerErrorScreenPasscodeAM = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const { title, subtitle, icon } = statusData.online;

    return (
        <SystemMessage className={cn('error-screen')}>
            <div className={cn('error-screen-main')}>
                <SystemMessage.Graphic>
                    <SuperEllipse size={80}>{icon}</SuperEllipse>
                </SystemMessage.Graphic>
                <SystemMessage.Title>{title}</SystemMessage.Title>
                <SystemMessage.Subtitle>{subtitle}</SystemMessage.Subtitle>
            </div>

            <SystemMessage.Controls>
                <Button
                    block={true}
                    view='secondary'
                    onClick={() =>
                        navigate({
                            pathname: Routes.CARD_ACCOUNT,
                            search,
                        })
                    }
                >
                    Попробовать ещё раз
                </Button>
                <Gap size='m' direction='horizontal' />
            </SystemMessage.Controls>
        </SystemMessage>
    );
};
