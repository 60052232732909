import React, { SVGAttributes } from 'react';

export const BankLogo: React.FC<SVGAttributes<SVGElement>> = (props) => (
    <svg width="30" height="46" viewBox="0 0 30 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="M14.9607 6.88513L10.7529 19.4108H19.0907L15.1164 6.88513H14.9607ZM15.2723 0C18.6504 0 19.6362 2.01805 20.4915 4.57288L29.3373 31H22.7919L20.8049 24.7173H8.96065L6.81776 31H0.662057L9.94459 4.57288C10.8452 2.00976 11.8942 0 15.2723 0ZM30 45.16H0V39.16H30V45.16Z"
            fill="#EF3124"
            fillRule="evenodd"
        />
    </svg>
);
