import React, { useEffect, useRef } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validationPasscodeАM } from '#/src/lib/client-validation/authorization';
import { ButtonNames, FormStatus, PasscodeType } from '#/src/models';
import { useRequestPasscodeAuthorizationMutation } from '#/src/store/api/passcode-api';
import { selectClientId } from '#/src/store/redux/app/selectors';
import {
    selectPasscode,
    selectPasscodeActiveType,
    selectPasscodeAttemptsLeft,
    selectPasscodeError,
    selectPasscodeErrorPage,
    selectPasscodeFormStatus,
    selectPasscodeShowToastError,
} from '#/src/store/redux/passcode';
import {
    passcodeErrorSet,
    passcodeUpdated,
    showToastErrorSet,
} from '#/src/store/redux/passcode/slice';

import StatusMessage from '../../ui/status-message';
import ActivePasscode from '../active-passcode';
import SelectTypePasscode from '../select-type-passcode';
import ToastError from '../toast-error';

import { ErrorScreenPasscodeAM } from './components/error-screen';

import './new-passcode.css';

export const cn = createCn('new-passcode');

const NewPasscodeMobile = () => {
    const dispatch = useAppDispatch();

    const [requestPasscodeAuthorization] = useRequestPasscodeAuthorizationMutation();

    const passcode = useAppSelector(selectPasscode);
    const attemptsLeft = useAppSelector(selectPasscodeAttemptsLeft);
    const formStatus = useAppSelector(selectPasscodeFormStatus);
    const validServerError = useAppSelector(selectPasscodeErrorPage);
    const clientId = useAppSelector(selectClientId);
    const error = useAppSelector(selectPasscodeError);
    const activeTypePasscode = useAppSelector(selectPasscodeActiveType);
    const showToastError = useAppSelector(selectPasscodeShowToastError);

    const containerRef = useRef(null);

    const isAlphaNumeric = activeTypePasscode === PasscodeType.Alphanumeric;

    const classMapButton = {
        [PasscodeType.Alphanumeric]: 'button-wrapper-alphanumeric',
        [PasscodeType.Numeric]: 'button-wrapper',
        [PasscodeType.GraphicPattern]: 'button-wrapper-patternlock',
    };

    useEffect(() => {
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', clientId);

        return () => {
            dispatch(
                passcodeUpdated({
                    error: '',
                    passcode: '',
                    attemptsLeft: 2,
                    serverErrorId: '',
                }),
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = async () => {
        const validation = validationPasscodeАM(passcode, activeTypePasscode);

        if (validation === null) {
            await requestPasscodeAuthorization();
        } else {
            dispatch(passcodeErrorSet(validation));
            dispatch(showToastErrorSet(true));
        }
    };

    if (!attemptsLeft) {
        if (validServerError) {
            return <ErrorScreenPasscodeAM />;
        }

        return <StatusMessage />;
    }

    return (
        <Form
            className={cn('container')}
            noValidate={true}
            onSubmit={handleClick}
            autocomplete={false}
        >
            <div className={cn('toast-сontainer')} ref={containerRef}>
                <ToastError anchorElement={containerRef.current} />
            </div>
            <div>
                <Typography.TitleMobile
                    className={cn('title')}
                    view='small'
                    tag='div'
                    color='primary'
                >
                    Введите секретный код
                </Typography.TitleMobile>
                <Typography.Text view='primary-medium' tag='div' className={cn('text')}>
                    Вы придумали его и&nbsp;использовали для&nbsp;входа на&nbsp;этом или&nbsp;другом
                    устройстве
                </Typography.Text>
            </div>

            <div className={cn(isAlphaNumeric ? 'alfanumeric-container' : 'passcode-container')}>
                <ActivePasscode />

                <div className={cn(classMapButton[activeTypePasscode])}>
                    <Button
                        block={true}
                        view='primary'
                        disabled={isAlphaNumeric ? !!error : showToastError}
                        onClick={handleClick}
                        loading={formStatus === FormStatus.SubmitProcess}
                    >
                        {ButtonNames.continue}
                    </Button>
                    <SelectTypePasscode />
                </div>
            </div>
        </Form>
    );
};

export default NewPasscodeMobile;
