import React from 'react';

import DesignCheck from '#/src/components/ui/design-check';

import NewPasscodeMobile from './new-passcode-mobile';
import PasscodeBrowser from './passcode-browser';

const NewPasscode = () => (
    <DesignCheck
        defaultComponent={<PasscodeBrowser />}
        defaultMobileComponent={<PasscodeBrowser />}
        mobileComponent={<NewPasscodeMobile />}
    />
);

export default NewPasscode;
