import { createSelector } from 'reselect';
import { ApplicationState } from '#/src/store/types';

/** Функция получения общего состояния приложения. */
export const getSettingsState = (state: ApplicationState) => state?.Settings;

export const selectContextRoot = createSelector(
    [getSettingsState],
    (Settings) => Settings?.contextRoot,
);
