import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { AcrValues, ButtonNames, Routes } from '#/src/models';
import { useInitializeBiometryMutation } from '#/src/store/api/biometry-api';
import { useInitializePasscodeMutation } from '#/src/store/api/passcode-api';
import { getClientId, isAppVersionHasBiometryAuth } from '#/src/store/redux/app/selectors';
import { selectRequestDataInit } from '#/src/store/redux/biometry';
import { scanFaceModule } from '#/src/store/redux/biometry/fetchAuhorizeBiometry';
import { selectPasscodeTypes } from '#/src/store/redux/passcode';
import { isAltAuthModalOpenSet } from '#/src/store/redux/passcode/slice';

import BsAltLogin from './components/bs-alt-login';

import './alt-login-mobile.css';

export const cn = createCn('alt-login-mobile');

const AlternativeLoginMobile = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const clientId = useAppSelector(getClientId);
    const isSupportBiometryAuth = useAppSelector(isAppVersionHasBiometryAuth);
    const initRequest = useAppSelector(selectRequestDataInit(AcrValues.biom));
    const passcodeTypes = useAppSelector(selectPasscodeTypes);

    const [initializePasscode, { isSuccess: isPasscodeSuccess, isLoading: isPasscodeLoading }] =
        useInitializePasscodeMutation();
    const [initializeBiometry, { isSuccess: isBiometrySuccess, isLoading: isBiometryLoading }] =
        useInitializeBiometryMutation();

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const isInitializing = isPasscodeLoading || isBiometryLoading;

    const authMethods = {
        passcode: isPasscodeSuccess,
        biometry: isBiometrySuccess && isSupportBiometryAuth,
        tempPassword: true, // tempPassword всегда доступен
    };

    const getNavigationPath = () => {
        const successfulAuthMethods = Object.values(authMethods).filter(Boolean).length;

        if (successfulAuthMethods >= 2) {
            return 'modal';
        }
        if (authMethods.passcode) {
            return passcodeTypes.length === 0 ? Routes.PASSCODE : Routes.NEW_PASSCODE;
        }
        if (authMethods.biometry) {
            dispatch(scanFaceModule());

            return null;
        }

        return Routes.TEMP_PASSWORD;
    };

    const handleAlternativeLogin = () => {
        if (isInitializing) {
            setIsButtonLoading(true);

            return;
        }

        const path = getNavigationPath();

        if (path === 'modal') {
            dispatch(isAltAuthModalOpenSet(true));
        } else if (path) {
            navigate({ pathname: path, search });
        }

        setIsButtonLoading(false);
        trackUserEvent('Auth Page', 'Click', 'Click on button Another way to enter', clientId);
    };

    useEffect(() => {
        (async function initialize() {
            await initializePasscode();
            await initializeBiometry(initRequest);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isInitializing && isButtonLoading) {
            handleAlternativeLogin();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitializing, isButtonLoading]);

    return (
        <React.Fragment>
            <Button
                dataTestId='alternative-button'
                className={cn('button-wrapper')}
                block={true}
                type='button'
                onClick={handleAlternativeLogin}
                loading={isButtonLoading}
            >
                {ButtonNames.alternativeLogin}
            </Button>

            <BsAltLogin />
        </React.Fragment>
    );
};

export default AlternativeLoginMobile;
